import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation, useSearchParams } from "react-router-dom";
import Dropdown from "../components/ProductDropdown";
import { BounceEffect, LiveIndicator } from "../custom/animations";
import BrandListCompact from "../components/BrandListCompact";
import ReactPaginate from "react-paginate";
import { trackEvent } from "../custom/analytics";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import { Regions, creatineIngredients } from "../custom/functions";
import PopUp from "../components/PopUp";
import { Helmet } from "react-helmet-async";

// CSS FOLD
const NewPodiumDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: static;
  padding: 0px 0px 30px 0px;
  width: 1500px;
  animation: ${(props) => props.filtered && BounceEffect} 0.25s ease 1;

  @media (max-width: 760px) {
    display: none;
  }
`;
const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  animation: ${(props) => props.filtered && BounceEffect} 0.25s ease 1;

  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
    margin-bottom: 2px;
  }
`;
const FilterSubtitle = styled.p`
  color: gray;
  font-size: 1rem;
  font-family: San Francisco Regular;
  padding: 0px 20px;
  max-width: 60%;
  cursor: pointer;

  @media (max-width: 760px) {
    font-size: 0.9rem;
    max-width: 90%;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:active {
    text-decoration: underline;
  }
`;
const Title = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  margin: 20px 0px 0px 0px;
  width: 90%;

  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
`;
const FilterDiv = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding: 0px 0px 20px 0px;

  @media (max-width: 900px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;
const FilterButtonDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding: 0px 0px 20px 0px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;
const FilterButtons = styled.button`
  all: unset;
  display: block;
  text-decoration: none;
  font-family: San Francisco Bold;
  width 120px;
  height: 40px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  margin: 0px 0px 20px 0px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;
const ListDiv = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  padding: 0px 0px 20px 0px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
const ListShopText = styled.p`
  font-size: 1rem;
  color: black;
  margin: auto;
  line-height: 40px;
  position: relative;
`;
const StyledReactPaginate = styled(ReactPaginate).attrs({
  //activeClassName: 'active'
})`
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  justify-content: center;
  list-style: none;
  padding: 0px;

  li {
    padding-left: 10px;
    padding-right: 10px;
  }

  li.selected {
    text-decoration: underline;
  }
`;

const SuggestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  // margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // margin-bottom: 15px;
  cursor: grab;
  user-select: none; // Prevent text selection during drag

  &:active {
    cursor: grabbing;
  }

  overflow-x: auto; // Changed from 'scroll' to 'auto'

  @media (min-width: 760px) {
    width: 70%;
    max-width: 700px;
  }

  // Hiding the scrollbar for WebKit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox 64+
  scrollbar-width: none; // Hides the scrollbar in Firefox

  // For Internet Explorer and Edge
  -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
`;
const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;
const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;
  // background: gray;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 3px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
const RankInfo = styled.div`
  display: flex;
  align-items: left;
  width: 95%;
  padding-left: 10px;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const RankText = styled.p`
  font-size: 14px;
  color: gray;
  margin-top: 0px;
  text-align: left;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;

const Type = [
  {
    value: "monohydrate",
    label: "Monohydrate",
  },
  {
    value: "micronized",
    label: "Micronized",
  },
  {
    value: "hcl",
    label: "HCl",
  },
];

const Patent = [
  {
    value: "true",
    label: "Patented",
  },
];

function responseMaker(type, patent) {
  let title = "Creatine";

  if (!type && !patent) {
    return "All Creatine";
  }

  if (type === "monohydrate") {
    title = "Creatine Monohydrate";
  }
  if (type === "micronized") {
    title = "Micronized Creatine Monohydrate";
  }
  if (type === "hcl") {
    title = "Creatine HCl";
  }

  if (patent) {
    title = "Patented " + title;
  }

  return title;
}

function newapiHandler(filter, patent, userCountry) {
  let link = "/api/creatine?";
  const params = new URLSearchParams();

  if (filter) {
    params.append("filter", filter);
  }
  if (patent) {
    params.append("patent", "true");
  }

  params.append("region", userCountry);

  link += params.toString();

  return link;
}

const CreatineFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [infoActive, setInfoActive] = useState(false);
  const [finalItems, setFinalItems] = useState([]);
  const [typeSelected, setType] = useState(
    searchParams.get("type") !== null
      ? searchParams.get("type").toLowerCase()
      : null
  );
  const [regionSelected, setRegion] = useState(null);
  const [patent, setPatent] = useState(
    searchParams.get("patent") !== null
      ? searchParams.get("patent").toLowerCase()
      : null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [typeList, setTypeList] = useState([...Type]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const ref = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const startDragging = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - ref.current.offsetLeft);
    setScrollLeft(ref.current.scrollLeft);
  };

  const stopDragging = (event) => {
    setIsDragging(false);
  };

  const whileDragging = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - ref.current.offsetLeft;
    const walk = (x - startX) * 3; // the *3 is the scroll speed factor
    ref.current.scrollLeft = scrollLeft - walk;
  };

  const fetchData = async () => {
    try {
      // console.log("fetchData called");
      setLoading(true);
      const response = await fetch(
        newapiHandler(typeSelected, patent, regionSelected)
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent("Filter", "Filtered: Creatine", typeSelected || "");
      // setTitle(responseMaker(typeSelected, patent));

      if (result.length > 0) {
        setEmptySearch(false);
        const params = new URLSearchParams();
        if (typeSelected) params.append("type", typeSelected);
        if (patent) params.append("patent", patent);
        if (regionSelected) params.append("region", regionSelected);
        setSearchParams(params, { replace: true });

        props.setCreatineFilter([...result]);
        props.setCreatineFilterLink("/creatine?" + params.toString());

        let previousValueRank = null;
        let currentRank = 1;
        let previousOldValueRank = null;
        let oldCurrentRank = 1;

        // First, calculate the current ranks while handling ties
        const rankedItems = result.map((item, index) => {
          if (
            previousValueRank !== null &&
            item.value_rank === previousValueRank
          ) {
            currentRank--;
          }
          const rankToShow = currentRank;
          previousValueRank = item.value_rank;
          currentRank++;
          return { ...item, currentRank: rankToShow };
        });

        // Sort items by old_value_rank
        const sortedByOldValueRank = [...rankedItems].sort(
          (a, b) => a.old_value_rank - b.old_value_rank
        );

        // Calculate old ranks while handling ties
        const rankedOldItems = sortedByOldValueRank.map((item, index) => {
          if (
            previousOldValueRank !== null &&
            item.old_value_rank === previousOldValueRank
          ) {
            oldCurrentRank--;
          }
          const oldRankToShow = oldCurrentRank;
          previousOldValueRank = item.old_value_rank;
          oldCurrentRank++;
          return { ...item, oldRank: oldRankToShow };
        });

        // Merge old ranks back into the original items based on value_rank
        const mergedItems = rankedItems.map((item) => {
          const rankedOldItem = rankedOldItems.find(
            (i) =>
              i.brand === item.brand &&
              i.type === item.type &&
              i.patent === item.patent
          );
          return {
            ...item,
            oldRank: rankedOldItem ? rankedOldItem.oldRank : null,
          };
        });
        // console.log(mergedItems);
        setFinalItems(mergedItems);
        setLoading(false);
      } else {
        setEmptySearch(true);
      }
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  const reorderList = (activeItem, list) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);

    return [activeItem, ...filteredList];
  };

  const removeActive = (activeItem, list, selectedLength) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);
    filteredList.push(activeItem);

    const firstHalf = filteredList.slice(0, selectedLength - 1);
    const secondHalf = filteredList.slice(selectedLength - 1).sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });

    return [...firstHalf, ...secondHalf];
  };

  const handleTypeClick = (item) => {
    setType((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return null;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const handlePatentClick = (item) => {
    setPatent((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return null;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  useEffect(() => {
    if (regionSelected === null && props.userCountry) {
      setRegion(searchParams.get("region") || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    // window.scroll(0, 0);
    fetchData();
  }, [typeSelected, patent, regionSelected]);

  const getStyle = (item, list) => {
    return item.value === list
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return item.value === list
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  return (
    <center style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Creatine Filter</title>
        <meta
          name="description"
          content="Discover the best value Creatine with our value ranking system. Whether you want micronized, hcl, or regular monohydrate, our rankings highlight top Creatine based on value."
        />
        <meta
          name="keywords"
          content="best creatine, creatine rankings, creatine supplement, creatine value, creatine monohydrate, creatine hydrochloride, creatine hcl, micronized creatine, top creatine, effective creatine, affordable creatine, workout supplements, creatine comparison, ingredient-based creatine, value creatine, creatine definition"
        />
        <meta property="og:title" content="Creatine Filter" />
        <meta
          property="og:description"
          content="Discover the best value Creatine with our value ranking system. Whether you want micronized, hcl, or regular monohydrate, our rankings highlight top Creatine based on value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
        {/* </StyledLink>
          <BackText style={{color:"gray"}}>{rank} Place</BackText> */}
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Creatine Value. <br />
            Formula: (Total Servings * Creatine Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          finalItems
            .slice(0, 3)
            .map(
              (item, index) =>
                item && (
                  <BrandListCompact
                    item={item}
                    filterRank={item.currentRank}
                    oldRank={item.oldRank}
                    userCountry={regionSelected}
                    ticker={true}
                    ai={true}
                    key={index}
                    loading={false}
                  />
                )
            )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <Title>
        {!emptySearch
          ? responseMaker(typeSelected, patent)
          : "No Search Results"}
      </Title>
      <FilterSubtitle onClick={!emptySearch ? openPopup : null}>
        {!emptySearch && (
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
        )}
        {!emptySearch
          ? "Ranked by Creatine value."
          : "Oops! Your search didn't return products. Please try again."}
      </FilterSubtitle>
      {/* <FilterDiv>
        <Dropdown
          className={false && "invalid"}
          placeholder="Type: "
          options={Type}
          value={typeSelected}
          selected={setType}
        />
      </FilterDiv> */}
      <SuggestionDiv
        ref={ref}
        onMouseDown={startDragging}
        onMouseLeave={stopDragging}
        onMouseUp={stopDragging}
        onMouseMove={whileDragging}
        style={{ paddingBottom: "10px" }}
      >
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv style={{ paddingTop: "5px", paddingBottom: "10px" }}>
        {/* <SuggestionText>Type:</SuggestionText> */}
        {typeList.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleTypeClick(item)}
                style={getStyle(item, typeSelected)}
              >
                <SuggestionText style={getTextStyle(item, typeSelected)}>
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv style={{ paddingTop: "5px", marginBottom: "30px" }}>
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Patent.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handlePatentClick(item)}
                style={getStyle(item, patent)}
              >
                <SuggestionText style={getTextStyle(item, patent)}>
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      {/* <FilterButtonDiv>
        <FilterButtons onClick={typeSelected && handleClick}>
          <ListShopText>Filter</ListShopText>
        </FilterButtons>
        <FilterButtons
          onClick={() => {
            setType(null);
          }}
        >
          <ListShopText>Clear</ListShopText>
        </FilterButtons>
      </FilterButtonDiv> */}
      <ListDiv>
        {!loading ? (
          finalItems
            .slice(3)
            .map(
              (item, index) =>
                item && (
                  <BrandListCompact
                    item={item}
                    filterRank={item.currentRank}
                    oldRank={item.oldRank}
                    userCountry={regionSelected}
                    ticker={true}
                    ai={true}
                    key={index}
                    loading={false}
                  />
                )
            )
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={typeSelected ? [typeSelected] : ["monohydrate"]}
        ingredientList={creatineIngredients}
      />
      {/* <StyledReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        previousLabel={"Prev"}
      /> */}
    </center>
  );
};

export default CreatineFilter;
