import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation, useSearchParams } from "react-router-dom";
import Dropdown from "../components/ProductDropdown";
import { BounceEffect } from "../custom/animations";
import BrandListCompact from "../components/BrandListCompact";
import ReactPaginate from "react-paginate";
import { trackEvent } from "../custom/analytics";
import {
  filterTieBreaker,
  itemFilter,
  preIngredients,
  tieBreaker,
} from "../custom/functions";
import { titleMakerPW, subMakerPW } from "../custom/functions";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import { LiveIndicator } from "../custom/animations";
import { Regions } from "../custom/functions";
import PopUp from "../components/PopUp";
import IngredientRankPopUp from "../components/IngredientRankPopUp";
import ExcludeRankPopUp from "../components/ExcludeRankPopUp";
import BrandListPlaceholder from "../components/BrandListPlaceholder";
import { Helmet } from "react-helmet-async";

// CSS FOLD
const NewPodiumDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: static;
  padding: 0px 0px 30px 0px;
  width: 1500px;
  animation: ${(props) => props.filtered && BounceEffect} 0.25s ease 1;

  @media (max-width: 760px) {
    display: none;
  }
`;
const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  animation: ${(props) => props.filtered && BounceEffect} 0.25s ease 1;

  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
    margin-bottom: 2px;
  }
`;
const FilterSubtitle = styled.p`
  color: gray;
  font-size: 1rem;
  font-family: San Francisco Regular;
  padding: 0px 20px;
  max-width: 60%;
  cursor: pointer;

  @media (max-width: 760px) {
    font-size: 0.9rem;
    max-width: 90%;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:active {
    text-decoration: underline;
  }
`;
const Title = styled.p`
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  margin: 20px 0px 0px 0px;
  width: 90%;
  padding: 0px 20px;

  @media (max-width: 900px) {
    font-size: 1.3rem;
  }
`;
const FilterDiv = styled.div`
  width: 50%;
  max-width: 700px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding: 0px 0px 20px 0px;

  @media (max-width: 900px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;
const FilterButtonDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: static;
  padding: 0px 0px 20px 0px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;
const FilterButtons = styled.button`
  all: unset;
  // display: block;
  // text-decoration: none;
  // font-family: San Francisco Bold;
  // width 120px;
  // height: 40px;
  // background-color: #efefef;
  // border-radius: 10px;
  // position: static;
  cursor: pointer;
  text-decoration: underline;
  height: 30px;
  line-height: 14px;
  font-size: 14px;
  color: black;
  font-family: San Francisco Regular;
  transition-duration: 0.25s;
  margin: 0px 0px 20px 0px;
  // box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  // @media (min-width: 760px) {
  //   &:hover {
  //     box-shadow: 0px 0px 5px black;
  //   }
  // }
`;
const ListDiv = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;
  padding: 0px 0px 20px 0px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
  }
`;
const ListShopText = styled.p`
  font-size: 14px;
  color: gray;
  margin: auto;
`;
const StyledReactPaginate = styled(ReactPaginate).attrs({
  //activeClassName: 'active'
})`
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  justify-content: center;
  list-style: none;
  padding: 0px;

  li {
    padding-left: 10px;
    padding-right: 10px;
  }

  li.selected {
    text-decoration: underline;
  }
`;
const SuggestionDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  // margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  // margin-bottom: 15px;
  cursor: grab;
  user-select: none; // Prevent text selection during drag

  &:active {
    cursor: grabbing;
  }

  overflow-x: auto; // Changed from 'scroll' to 'auto'

  @media (min-width: 760px) {
    width: 70%;
    max-width: 700px;
  }

  // Hiding the scrollbar for WebKit browsers
  &::-webkit-scrollbar {
    display: none;
  }

  // For Firefox 64+
  scrollbar-width: none; // Hides the scrollbar in Firefox

  // For Internet Explorer and Edge
  -ms-overflow-style: none; // Hides the scrollbar in IE and Edge
`;
const Suggestion = styled.button`
  all: unset;
  display: block;
  background: #efefef;
  border-radius: 7px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:active {
    cursor: grabbing;
  }

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;

const SuggestionText = styled.div`
  color: black;
  font-family: San Francisco Bold;
  font-size: 1rem;
  position: static;
  text-align: center;
  margin: 5px;
  white-space: nowrap;

  @media (max-width: 760px) {
    font-size: 0.9rem;
  }
`;
const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
const BackButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 3px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;
const RankInfo = styled.div`
  display: flex;
  align-items: left;
  width: 95%;
  padding-left: 10px;

  @media (max-width: 760px) {
    width: 90%;
  }
`;
const RankText = styled.p`
  font-size: 14px;
  color: gray;
  margin-top: 0px;
  text-align: left;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;

// const Ingredients = preIngredients;
const Ingredients = [
  { value: "alphagpc", label: "Alpha GPC" },
  { value: "betaalanine", label: "Beta Alanine" },
  { value: "betaine", label: "Betaine Anhydrous" },
  { value: "caffeine", label: "Caffeine" },
  { value: "citrulline", label: "Citrulline" },
  { value: "creatine", label: "Creatine Monohydrate" },
  { value: "glycerol", label: "Glycerol" },
  { value: "huperzinea", label: "Huperzine-A" },
  { value: "malicacid", label: "Malic Acid" },
  { value: "nitrosigne", label: "Nitrosigine®" },
  // { value: "himalayan", label: "Pink Himalayan Sea Salt" },
  { value: "taurine", label: "Taurine" },
  { value: "theanine", label: "Theanine" },
  { value: "tyrosine", label: "Tyrosine" },
];

const SelectCategories = [
  { value: "rank", label: "Rank Ingredients" },
  { value: "exclude", label: "Exclude Ingredients" },
];

function newapiHandler(selectedRank, selectedExclude, userCountry) {
  let link = "/api/preworkout?";
  const params = new URLSearchParams();

  selectedRank.forEach((ingredient, index) => {
    const value = preIngredients.find((i) => i.value === ingredient)?.value;
    if (value) {
      params.append(`ingredient${index + 1}`, value);
    }
  });

  selectedExclude.forEach((exclude, index) => {
    const value = preIngredients.find((i) => i.value === exclude)?.value;
    if (value) {
      params.append(`exclude${index + 1}`, value);
    }
  });

  params.append(`region`, userCountry);

  link += params.toString();
  // console.log(link);
  return link;
}

const PreFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [advancedMode, setAdvancedMode] = useState(false);
  const [infoActive, setInfoActive] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [regionSelected, setRegion] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isRankPopupOpen, setRankPopupOpen] = useState(false);
  const [isExcludePopupOpen, setExcludePopupOpen] = useState(false);
  const [originalMax, setOriginalMax] = useState(-Infinity);

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => setPopupOpen(false);

  const openRankPopup = () => setRankPopupOpen(true);
  const closeRankPopup = () => setRankPopupOpen(false);

  const openExcludePopup = () => setExcludePopupOpen(true);
  const closeExcludePopup = () => setExcludePopupOpen(false);

  const getParamArray = (paramPrefix, rangePrefix) => {
    const ingredientsArray = [];
    const rangeDataArray = [];

    for (let i = 1; i <= 10; i++) {
      const ingredient = searchParams.get(`${paramPrefix}${i}`);
      const range = searchParams.get(`${rangePrefix}${i}`);

      if (ingredient) {
        ingredientsArray.push(ingredient.toLowerCase());

        // Parse the range into min and max
        let min = null;
        let max = null;
        if (range) {
          const [parsedMin, parsedMax] = range.split("-");
          min = parsedMin ? parseInt(parsedMin, 10) : null;
          max = parsedMax ? parseInt(parsedMax, 10) : null;
        }

        // Add the correct ingredient and parsed min-max values
        rangeDataArray.push({
          ingredient: ingredient.toLowerCase(), // Correct ingredient name
          min: min,
          max: max,
          hide: min === null || max === null,
        });
      }
    }

    return { ingredientsArray, rangeDataArray };
  };

  const setParamArray = (params, paramPrefix, valuesArray) => {
    // Remove old parameters with the same prefix
    for (let i = 1; i <= 10; i++) {
      params.delete(`${paramPrefix}${i}`);
    }

    // Add new parameters
    valuesArray.forEach((value, index) => {
      if (value) {
        params.set(`${paramPrefix}${index + 1}`, value);
      }
    });
  };

  const setParamRangeArray = (
    params,
    paramPrefix,
    rangePrefix,
    ingredientsArray,
    rangeDataArray
  ) => {
    // Remove old parameters with the same prefix
    for (let i = 1; i <= 10; i++) {
      params.delete(`${paramPrefix}${i}`);
      params.delete(`${rangePrefix}${i}`);
    }

    // Add new parameters for ingredients and only add range if hide is false
    ingredientsArray.forEach((ingredient, index) => {
      // Always add the ingredient
      params.set(`${paramPrefix}${index + 1}`, ingredient);

      // Find the corresponding range for this ingredient
      const range = rangeDataArray.find((r) => r.ingredient === ingredient);

      // Add range only if hide is false and both min and max are available
      if (range && !range.hide && range.min !== null && range.max !== null) {
        const rangeValue = `${range.min}-${range.max}`;
        params.set(`${rangePrefix}${index + 1}`, rangeValue);
      }
    });
  };

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalItems, setOriginalItems] = useState(null);
  const [finalItems, setFinalItems] = useState([]);
  const [emptySearch, setEmptySearch] = useState(false);
  const [resetting, setResetting] = useState(false);

  const [selectedRankIngredients, setSelectedRankIngredients] = useState(() => {
    const rankIngredients = getParamArray(
      "ingredient",
      "ingredientRange"
    ).ingredientsArray;
    return rankIngredients.length > 0 && rankIngredients
      ? rankIngredients
      : ["caffeine"];
  });
  const [rangeData, setRangeData] = useState(() => {
    return getParamArray("ingredient", "ingredientRange").rangeDataArray;
  });

  // console.log(rangeData);
  const [selectedExcludeIngredients, setSelectedExcludeIngredients] = useState(
    () => {
      return getParamArray("exclude").ingredientsArray;
    }
  );
  const [ingredientList, setIngredientList] = useState(
    !advancedMode ? [...Ingredients] : [...preIngredients]
  );
  const [excludeList, setExcludeList] = useState(
    !advancedMode ? [...Ingredients] : [...preIngredients]
  );

  const reorderList = (activeItem, list) => {
    const filteredList = list.filter((item) => item.value !== activeItem.value);

    return [activeItem, ...filteredList];
  };

  const removeActive = (activeItem, list, selectedLength) => {
    // Remove activeItem from the list
    const filteredList = list.filter((item) => item.value !== activeItem.value);

    // Add activeItem to the end
    filteredList.push(activeItem);

    // Split the list into two parts and sort only the second half
    const firstHalf = filteredList.slice(0, selectedLength - 1);
    const secondHalf = filteredList
      .slice(selectedLength - 1)
      .sort((a, b) => a.value.localeCompare(b.value));

    // Merge the two halves back together
    return [...firstHalf, ...secondHalf];
  };

  const handleCategoryClick = (item) => {
    setSelectedCategory((prevSelected) => {
      if (item.value === prevSelected) {
        return null;
      } else if (item.value === "rank") {
        openRankPopup();
      } else if (item.value === "exclude") {
        openExcludePopup(true);
      } else {
        return item.value;
      }
    });
  };

  const handleRegionClick = (item) => {
    // props.setUserCountry(item.value);
    setRegion((prevSelected) => {
      if (prevSelected === item.value) {
        // setTypeList(
        //   removeActive(item, typeList, 1)
        // );
        return prevSelected;
      } else if (prevSelected !== item.value) {
        // setTypeList(reorderList(item, typeList));
        return item.value;
      } else {
        return prevSelected;
      }
    });
  };

  const reorderSelectedItems = (selectedItems, fullList) => {
    // Filter the list to get the selected items first
    const selectedItemsList = fullList.filter((item) =>
      selectedItems.includes(item.value)
    );
    // Filter the list to get the non-selected items
    const nonSelectedItemsList = fullList.filter(
      (item) => !selectedItems.includes(item.value)
    );
    // Concatenate the selected items at the front and non-selected items at the end
    return [...selectedItemsList, ...nonSelectedItemsList];
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        // apiHandler(ingredientOne, ingredientTwo, excludeOne, excludeTwo)
        newapiHandler(
          selectedRankIngredients,
          selectedExcludeIngredients,
          regionSelected
        )
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      trackEvent("Filter", "Filtered: Pre-Workout", "");
      if (result.length > 0) {
        setEmptySearch(false);
        // setMobilePodium([...result.slice(0, 3)]);
        // setList([...result.slice(3, result.length)]);

        const params = new URLSearchParams();
        setParamRangeArray(
          params,
          "ingredient",
          "ingredientRange",
          selectedRankIngredients,
          rangeData
        );
        setParamArray(params, "exclude", selectedExcludeIngredients);
        if (regionSelected) params.append("region", regionSelected);
        setSearchParams(params, { replace: true });
        setOriginalItems(result);
        props.setPreFilter([...result]);
        props.setPreFilterLink("/preworkout?" + params.toString());

        setIngredientList(
          reorderSelectedItems(selectedRankIngredients, ingredientList)
        );
        setExcludeList(
          reorderSelectedItems(selectedExcludeIngredients, excludeList)
        );

        if (resetting) {
          setRangeData([
            { ingredient: "caffeine", min: null, max: null, hide: true },
          ]);
          setResetting(false);
        }

        // checking if an ingredient is in advanced and showing
        selectedRankIngredients.map((ingredient) => {
          const exists = Ingredients.some((item) => item.value === ingredient);

          if (!exists) {
            setAdvancedMode(true);
            setIngredientList(
              reorderSelectedItems(selectedRankIngredients, [...preIngredients])
            );
          }

          return;
        });

        selectedExcludeIngredients.map((ingredient) => {
          const exists = Ingredients.some((item) => item.value === ingredient);

          if (!exists) {
            setAdvancedMode(true);
            setExcludeList(
              reorderSelectedItems(selectedExcludeIngredients, [
                ...preIngredients,
              ])
            );
          }

          return;
        });

        // write function to check for min and maxes for ingredients
        // create min max array {value, min, max}, etc
        // for item in min max array filter response[ingredientName] => min && respone[ingredientName] <= max
        // then send through to finalItems

        // console.log((result));
        setFinalItems(filterTieBreaker(result));
        setLoading(false);
      } else {
        setEmptySearch(true);
      }
    } catch (error) {
      setError(error); // Update the state with the error
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.userCountry]);

  // console.log(finalItems);
  useEffect(() => {
    if (regionSelected === null && props.userCountry) {
      setRegion(searchParams.get("region") || props.userCountry);
    }
  }, [props.userCountry, searchParams]);

  useEffect(() => {
    if (!regionSelected) return;
    fetchData();
  }, [selectedRankIngredients, selectedExcludeIngredients, regionSelected]);

  // useEffect() [min, max] -> take original items and filter then run through filterTieBreaker

  // useEffect(() => {

  // }, [min, max])

  const getStyle = (item, list) => {
    return list.includes(item.value)
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getTextStyle = (item, list) => {
    return list.includes(item.value)
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const getCategoryStyle = (item, selected) => {
    return item.value === selected
      ? { background: "#2e2e2e", color: "white" } // Adding color: "white" for better visibility
      : { color: "#efefef" };
  };

  const getCategoryTextStyle = (item, selected) => {
    return item.value === selected
      ? { color: "white" } // Adding color: "white" for better visibility
      : { color: "black" };
  };

  const modeToggle = () => {
    setAdvancedMode(!advancedMode);

    setIngredientList(
      reorderSelectedItems(
        selectedRankIngredients,
        advancedMode ? [...Ingredients] : [...preIngredients]
      )
    );
    setExcludeList(
      reorderSelectedItems(
        selectedExcludeIngredients,
        advancedMode ? [...Ingredients] : [...preIngredients]
      )
    );
    // setIngredientList(advancedMode ? [...Ingredients] : [...preIngredients]);
    // setExcludeList(advancedMode ? [...Ingredients] : [...preIngredients]);
  };

  const infoToggle = () => {
    setInfoActive(!infoActive);
  };

  const Reset = () => {
    setSelectedRankIngredients(["caffeine"]);
    setSelectedExcludeIngredients([]);
    setIngredientList(!advancedMode ? [...Ingredients] : [...preIngredients]);
    setExcludeList(!advancedMode ? [...Ingredients] : [...preIngredients]);
    // setRangeData([{ ingredient: "caffeine", min: 0, max: originalMax }]);
    setResetting(true);
  };

  // finding original ingredient max value
  useEffect(() => {
    if (
      !originalItems ||
      originalItems.length === 0 ||
      !selectedRankIngredients ||
      selectedRankIngredients.length === 0
    ) {
      // console.log("Data not available yet.");
      return; // Exit the effect early
    }

    let newMax = -Infinity;

    originalItems.forEach((item) => {
      const baseValue = parseFloat(
        item[selectedRankIngredients[selectedRankIngredients.length - 1]]
      );

      const ingredientTotal = item.twoscoop ? 2 * baseValue : baseValue;

      if (ingredientTotal > newMax) {
        newMax = ingredientTotal;
      }
    });

    // console.log("Setting originalMax to:", newMax);
    setOriginalMax(newMax);
  }, [originalItems, selectedRankIngredients, selectedExcludeIngredients]);

  // filters data based on rangedata
  useEffect(() => {
    // console.log("Filter Effect: ", originalItems);
    const params = new URLSearchParams();
    setParamRangeArray(
      params,
      "ingredient",
      "ingredientRange",
      selectedRankIngredients,
      rangeData
    );
    setParamArray(params, "exclude", selectedExcludeIngredients);
    if (regionSelected) params.append("region", regionSelected);
    setSearchParams(params, { replace: true });

    const filteredItems = itemFilter(originalItems, rangeData);

    setFinalItems(() => {
      if (!originalItems) return []; // Handle the case where originalItems is undefined

      return filteredItems;
    });
    // Set empty search to true if no items are filtered
    if (originalItems) {
      setEmptySearch(filteredItems.length === 0);
    }

    props.setPreFilter([...filteredItems]);
    props.setPreFilterLink("/preworkout?" + params.toString());
  }, [
    originalItems,
    rangeData,
    selectedRankIngredients,
    selectedExcludeIngredients,
  ]);

  // useEffect(() => {
  //   const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
  //   if (themeColorMetaTag) {
  //     if (isPopupOpen || isRankPopupOpen || isExcludePopupOpen) {
  //       themeColorMetaTag.setAttribute('content', '#7F7F7F'); // Set color when popup is open
  //     } else {
  //       themeColorMetaTag.setAttribute('content', 'white'); // Reset color when popup is closed
  //     }
  //   }
  // }, [isPopupOpen, isRankPopupOpen, isExcludePopupOpen]);

  return (
    <center style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>Pre-Workout Filter</title>
        <meta
          name="description"
          content="Discover the best value Pre-Workout with our ingredient value ranking system. Whether you want a high stim, low stim, or pump-focused formula, our rankings highlight top Pre-Workouts based on ingredient value."
        />
        <meta
          name="keywords"
          content="best pre-workout, pre-workout rankings, pre-workout supplement, pre-workout value, high stim pre-workout, low stim pre-workout, pump formula, nootropic pre-workout, caffeine free pre-workout, top pre-workout, effective pre-workout ingredients, affordable pre-workout, pre-workout for energy, pre-workout for pump, workout supplements, pre-workout comparison, ingredient-based pre-workout, ingredient value pre-workout, pre-workout filter, pre-workout ingredient definitions"
        />
        <meta property="og:title" content="Pre-Workout Filter" />
        <meta
          property="og:description"
          content="Discover the best value Pre-Workout with our ingredient value ranking system. Whether you want a high stim, low stim, or pump-focused formula, our rankings highlight top Pre-Workouts based on ingredient value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton onClick={infoToggle}>
          <ChartSVG />
          <BackText>Ranking Info {!infoActive ? "+" : "-"}</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      {infoActive && (
        <RankInfo>
          <RankText>
            Products are ranked by Total Selected Ingredient Value. <br />
            Formula: (Total Servings * Ingredient Serving) / Price
          </RankText>
        </RankInfo>
      )}
      <PodiumDivMobile style={{ paddingTop: "0px" }}>
        {!loading ? (
          <>
            {finalItems.slice(0, 3).map((item, index) => {
              if (!item) return null;
              return (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  userCountry={regionSelected}
                  ticker={true}
                  ai={true}
                  key={index + Date.now()}
                  loading={false}
                />
              );
            })}
            {/* Display placeholders if finalItems.length is less than 3 */}
            {finalItems.length < 3 &&
              Array.from({ length: 3 - finalItems.length }).map((_, i) => (
                <BrandListPlaceholder key={`placeholder-${i}`} index={i} />
              ))}
          </>
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true} />
            <BrandListCompact filterRank={2} loading={true} />
            <BrandListCompact filterRank={3} loading={true} />
          </>
        )}
      </PodiumDivMobile>

      <Title>
        {!emptySearch
          ? titleMakerPW(selectedExcludeIngredients)
          : "No Search Results"}
      </Title>
      <FilterSubtitle onClick={!emptySearch ? openPopup : null}>
        {!emptySearch && (
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
        )}
        {!emptySearch
          ? subMakerPW(selectedRankIngredients, rangeData)
          : "Oops! Your search didn't return products. Please try again."}
      </FilterSubtitle>
      <SuggestionDiv
        style={{ paddingBottom: "10px", justifyContent: "center" }}
      >
        {/* <SuggestionText>Type:</SuggestionText> */}
        {Regions.map(
          (item) =>
            item && (
              <Suggestion
                key={item.value}
                onClick={() => handleRegionClick(item)}
                style={getStyle(item, regionSelected || "")}
              >
                <SuggestionText
                  style={getTextStyle(item, regionSelected || "")}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <SuggestionDiv
        style={{
          paddingTop: "5px",
          marginBottom: "30px",
          justifyContent: "center",
        }}
      >
        {SelectCategories.map(
          (item) =>
            item &&
            !item.hide && (
              <Suggestion
                key={item.value}
                onClick={() => handleCategoryClick(item)}
                style={getCategoryStyle(item, selectedCategory)}
              >
                <SuggestionText
                  style={getCategoryTextStyle(item, selectedCategory)}
                >
                  {item.label}
                </SuggestionText>
              </Suggestion>
            )
        )}
      </SuggestionDiv>
      <ListDiv>
        {!loading ? (
          finalItems.slice(3).map((item, index) => {
            if (!item) return null;
            return (
              <BrandListCompact
                item={item}
                filterRank={item.currentRank}
                oldRank={item.oldRank}
                userCountry={regionSelected}
                ticker={true}
                ai={true}
                key={index + Date.now()}
                loading={false}
              />
            );
          })
        ) : (
          <>
            <BrandListCompact filterRank={4} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={5} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={6} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={7} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={8} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={9} loading={true}></BrandListCompact>
          </>
        )}
      </ListDiv>
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={selectedRankIngredients}
        ingredientList={preIngredients}
      />
      <IngredientRankPopUp
        category="preworkout"
        isOpen={isRankPopupOpen}
        onClose={closeRankPopup}
        selectedRankIngredients={selectedRankIngredients}
        setSelectedRankIngredients={setSelectedRankIngredients}
        ingredientList={ingredientList}
        setIngredientList={setIngredientList}
        selectedExcludeIngredients={selectedExcludeIngredients}
        removeActive={removeActive}
        reorderList={reorderList}
        getStyle={getStyle}
        getTextStyle={getTextStyle}
        responseLength={finalItems.length}
        loading={loading}
        modeToggle={modeToggle}
        advancedMode={advancedMode}
        emptySearch={emptySearch}
        Reset={Reset}
        rangeData={rangeData}
        setRangeData={setRangeData}
        originalMax={originalMax}
        originalItems={originalItems}
      />
      <ExcludeRankPopUp
        isOpen={isExcludePopupOpen}
        onClose={closeExcludePopup}
        selectedRankIngredients={selectedRankIngredients}
        setSelectedRankIngredients={setSelectedRankIngredients}
        selectedExcludeIngredients={selectedExcludeIngredients}
        setSelectedExcludeIngredients={setSelectedExcludeIngredients}
        excludeList={excludeList}
        setExcludeList={setExcludeList}
        removeActive={removeActive}
        reorderList={reorderList}
        getStyle={getStyle}
        getTextStyle={getTextStyle}
        responseLength={finalItems.length}
        loading={loading}
        modeToggle={modeToggle}
        advancedMode={advancedMode}
        emptySearch={emptySearch}
        Reset={Reset}
      />
      {/* <StyledReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        previousLabel={"Prev"}
      /> */}
    </center>
  );
};

export default PreFilter;
