import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactTyped } from "react-typed";
import PopUp from "../components/PopUp";
import BrandListCompact from "../components/BrandListCompact";
import {
  preIngredients,
  tieBreaker,
  searchPageGen,
  subMakerPW,
  proteinIngredients,
  subMakerProtein,
} from "../custom/functions";
import { ChartSVG, IngredientEyeSVG } from "../custom/svg";
import podiumGlyph from "../images/podiumglyph.png";
import { LiveIndicator } from "../custom/animations";
import { Helmet } from "react-helmet-async";

const ResponseDiv = styled.div`
  flex-direction: column;
  justify-content: left;
  align-items: left;
  position: static;
  width: 70%;
  max-width: 700px;
  display: flex;
  // background: black;

  @media (max-width: 760px) {
    width: 100%;
    max-width: 700px;
  }
`;

const ResponseTitle = styled.p`
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  position: static;
  text-align: left;
  padding-left: 40px;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
    padding-left: 30px;
  }
`;
const ResponseSubtitle = styled.p`
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-left: 40px;
  padding-right: 30px;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;
  cursor: pointer;

  @media (max-width: 760px) {
    padding-left: 30px;
    font-size: 0.9rem;
  }

  @media (min-width: 760px) {
    &:hover {
      text-decoration: ${(props) => (props.noUnderline ? "none" : "underline")};
    }
  }

  &:active {
    text-decoration: underline;
  }
`;
const SvgWrapper = styled.span`
  margin-right: 5px;
  margin-bottom: 3px;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 760px) {
    margin-bottom: 2px;
  }
`;
const PodiumDivMobile = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: static;
  width: 70%;
  max-width: 700px;
  gap: 10px;
  display: flex;

  // padding-bottom: 40px;

  @media (max-width: 760px) {
    display: flex;
    width: 95%;
    // padding-bottom: 30px;
  }
`;
const FilterButtonDiv = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: static;
  padding-top: 20px;

  @media (max-width: 900px) {
    width: 90%;
  }
`;
const ShowMoreButton = styled(Link)`
  text-decoration: none;
  width: 80px;
  height: 40px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 0px 5px black;
  }

  @media (max-width: 760px) {
    &:hover {
      box-shadow: none;
    }
  }
`;
const PodiumGlyphImg = styled.img`
  width: 40px;
  position: static;
`;
const TabBar = styled.div`
  display: block;
  height: 3px;
  width: 66.5%;
  max-width: 665px;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 20px;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    width: 90.25%;
  }
`;
const LastTabBar = styled.div`
  display: block;
  height: 3px;
  width: 66.5%;
  max-width: 665px;
  border-radius: 50px;
  background-color: #efefef;
  margin-top: 30px;
  margin-bottom: 0px;

  @media (max-width: 1000px) {
    width: 90.25%;
    margin-bottom: 30px;
  }
`;
const BackDiv = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-family: San Francisco Regular;
  padding-bottom: 10px;

  @media (max-width: 760px) {
    width: 90%;
    height: 50px;
    line-height: 12px;
    padding-bottom: 0px;
  }
`;
const BackButton = styled(Link)`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  padding-left: 3px;

  &:active {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;
  }
`;
const BackText = styled.p`
  font-size: 14px;
  color: black;
  // margin: 0px;

  @media (max-width: 760px) {
    font-size: 12px;
  }
`;

const ProteinExample = (props) => {
  const [exampleLoading, setExampleLoading] = useState(true);
  const [exampleOne, setExampleOne] = useState([]);
  const [exampleTwo, setExampleTwo] = useState([]);
  const [exampleThree, setExampleThree] = useState([]);
  const [exampleFour, setExampleFour] = useState([]);
  const [exampleFive, setExampleFive] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [popupIngredients, setPopupIngredients] = useState(null);
  const [popupIngredientList, setPopupIngredientList] = useState(null);

  const closePopup = () => setPopupOpen(false);

  const handlePopUp = (ingredients, ingredientList) => {
    setPopupOpen(true);
    setPopupIngredients(ingredients);
    setPopupIngredientList(ingredientList);
  };

  useEffect(() => {
    if (!props.userCountry) return;

    const fetchExampleData = (url) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject(new Error(`HTTP error! status: ${response.status}`));
          }
        } catch (error) {
          reject(error);
        }
      });
    };

    const fetchAllExamples = (userCountry) => {
      let fetchOne;
      let fetchTwo;
      let fetchThree;
      let fetchFour;
      let fetchFive;

      fetchOne = fetchExampleData(
        `/api/proteinpowder?filter=isolate&region=` + userCountry
      );
      fetchTwo = fetchExampleData(
        `/api/proteinpowder?rank=lowcal&region=` + userCountry
      );
      fetchThree = fetchExampleData(
        `/api/proteinpowder?filter=vegan&region=` + userCountry
      );
      fetchFour = fetchExampleData(
        `/api/proteinpowder?filter=clear&region=` + userCountry
      );
      fetchFive = fetchExampleData(`/api/proteinpowder?region=` + userCountry);

      setExampleLoading(true);

      Promise.all([fetchOne, fetchTwo, fetchThree, fetchFour, fetchFive])
        .then(([dataOne, dataTwo, dataThree, dataFour, dataFive]) => {
          setExampleOne(dataOne);
          setExampleTwo(dataTwo);
          setExampleThree(dataThree);
          setExampleFour(dataFour);
          setExampleFive(dataFive);
          setExampleLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchAllExamples(props.userCountry);
    // props.setPreFilter([]);
    // props.setPreFilterLink(null);
    // props.setProteinFilter([]);
    // props.setProteinFilterLink(null);
    // props.setElectrolyteFilter([]);
    // props.setElectrolyteFilterLink(null);
  }, [props.userCountry]);

  return (
    <center>
      <Helmet>
        <title>Protein Powder Rankings</title>
        <meta
          name="description"
          content="Discover the best value Protein Powder with our protein value ranking system. Whether you want a low calorie, whey isolate, or vegan formula, our rankings highlight top Protein Powders based on protein value."
        />
        <meta
          name="keywords"
          content="best protein powder, protein powder rankings, protein powder supplement, protein powder value, high calorie protein powder, low calorie protein powder, top protein powder, effective protein powder ingredients, affordable protein powder, workout supplements, protein powder comparison, ingredient-based protein powder, ingredient value protein powder, protein powder ingredient definitions"
        />
        <meta property="og:title" content="Protein Powder Rankings" />
        <meta
          property="og:description"
          content="Discover the best value Protein Powder with our protein value ranking system. Whether you want a low calorie, whey isolate, or vegan formula, our rankings highlight top Protein Powders based on protein value."
        />
      </Helmet>
      <BackDiv>
        {/* <StyledLink to={props.preFilterLink} method="get"> */}
        <BackButton to={"/proteinpowder"}>
          <ChartSVG />
          <BackText>Protein Powder Filter</BackText>
        </BackButton>
        <LiveIndicator />
      </BackDiv>
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Whey Isolate Protein Powder"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["isolate"], proteinIngredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein(null)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleOne).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleOne}
                  filterLink={searchPageGen(
                    "proteinpowder",
                    "isolate",
                    null,
                    null,
                    [],
                    []
                  )}
                  setFilter={props.setProteinFilter}
                  setFilterLink={props.setProteinFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen("proteinpowder", "isolate", null, null, [], [])}
        >
          <PodiumGlyphImg src={podiumGlyph} />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Low Calorie Protein Powder"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() =>
            handlePopUp(["calories", "protein"], proteinIngredients)
          }
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein("lowcal")}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleTwo).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleTwo}
                  filterLink={searchPageGen(
                    "proteinpowder",
                    null,
                    "lowcal",
                    null,
                    [],
                    []
                  )}
                  setFilter={props.setProteinFilter}
                  setFilterLink={props.setProteinFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen("proteinpowder", null, "lowcal", null, [], [])}
        >
          <PodiumGlyphImg src={podiumGlyph} />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Vegan Protein Powder"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
        <ResponseSubtitle
          onClick={() => handlePopUp(["protein"], proteinIngredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein(null)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleThree).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  ai={true}
                  userCountry={props.userCountry}
                  key={index}
                  filter={exampleThree}
                  filterLink={searchPageGen(
                    "preworkout",
                    "vegan",
                    null,
                    null,
                    [],

                    []
                  )}
                  setFilter={props.setProteinFilter}
                  setFilterLink={props.setProteinFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen(
            "preworkout",
            "vegan",
            null,
            null,
            [],

            []
          )}
        >
          <PodiumGlyphImg src={podiumGlyph} />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["Clear Whey Isolate Protein Powder"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        {/* <ResponseSubtitle>
                {props.userCountry === "GB"
                  ? "Ranked by Caffeine, Citrulline and Glycerol value."
                  : "Ranked by Citrulline, Glycerol and Malic Acid value."}
              </ResponseSubtitle> */}
        <ResponseSubtitle
          onClick={() => handlePopUp(["clear"], proteinIngredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein(null)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleFour).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  ai={true}
                  userCountry={props.userCountry}
                  key={index}
                  filter={exampleFour}
                  filterLink={searchPageGen(
                    "proteinpowder",
                    "clear",
                    null,
                    null,
                    [],
                    []
                  )}
                  setFilter={props.setProteinFilter}
                  setFilterLink={props.setProteinFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen("proteinpowder", "clear", null, null, [], [])}
        >
          <PodiumGlyphImg src={podiumGlyph} />
        </ShowMoreButton>
      </FilterButtonDiv>
      <TabBar />
      <ResponseDiv>
        <ResponseTitle>
          <ReactTyped
            strings={["All Protein Powder"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
          onClick={() => handlePopUp(["protein"], proteinIngredients)}
        >
          <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper>
          {subMakerProtein(null)}
        </ResponseSubtitle>
      </ResponseDiv>
      <PodiumDivMobile>
        {!exampleLoading ? (
          tieBreaker(exampleFive).map(
            (item, index) =>
              item && (
                <BrandListCompact
                  item={item}
                  filterRank={item.currentRank}
                  oldRank={item.oldRank}
                  ticker={true}
                  loading={false}
                  userCountry={props.userCountry}
                  ai={true}
                  key={index}
                  filter={exampleFive}
                  filterLink={searchPageGen(
                    "proteinpowder",
                    null,
                    null,
                    null,
                    [],
                    []
                  )}
                  setFilter={props.setProteinFilter}
                  setFilterLink={props.setProteinFilterLink}
                />
              )
          )
        ) : (
          <>
            <BrandListCompact filterRank={1} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={2} loading={true}></BrandListCompact>
            <BrandListCompact filterRank={3} loading={true}></BrandListCompact>
          </>
        )}
      </PodiumDivMobile>
      <FilterButtonDiv>
        <ShowMoreButton
          to={searchPageGen("proteinpowder", null, null, null, [], [])}
          style={{ marginBottom: "25px" }}
        >
          <PodiumGlyphImg src={podiumGlyph} />
        </ShowMoreButton>
      </FilterButtonDiv>
      {/* <LastTabBar /> */}
      <PopUp
        isOpen={isPopupOpen}
        onClose={closePopup}
        ingredients={popupIngredients}
        ingredientList={popupIngredientList}
      />
    </center>
  );
};

export default ProteinExample;
