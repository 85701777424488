import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams, Link } from "react-router-dom";
import { ReactTyped } from "react-typed";
import {
  CartSVG,
  CloseSVG,
  ExternalSVG,
  EyeSVG,
  LockSVG,
  RightArrowSVG,
  TableClearSVG,
  XSVG,
} from "../custom/svg";
import BrandListSearch from "../components/BrandListSearch";
import defaultproduct from "../images/defaultproduct.png";
import { GradientAnimation } from "../custom/animations";
import {
  creatineIngredients,
  electrolyteIngredients,
  massIngredients,
  preIngredients,
  proteinIngredients,
} from "../custom/functions";
import mainlogo from "../images/supphead.png";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet-async";

const DesktopCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 760px) {
    display: block;
  }
`;

const ResponseDiv = styled.div`
  flex-direction: column;
  justify-content: left;
  align-items: left;
  position: static;
  width: 50%;
  max-width: 700px;
  display: flex;
  //   background: black;

  @media (max-width: 760px) {
    width: 100%;
    max-width: 700px;
    // background: gray;
  }
`;

const ResponseTitle = styled.p`
  display: block;
  height: auto;
  font-family: San Francisco Bold;
  font-size: 1.4rem;
  position: static;
  text-align: left;
  padding-left: 40px;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.3rem;
    padding-left: 30px;
  }
`;
const ResponseSubtitle = styled.p`
  font-size: 1rem;
  font-family: San Francisco Regular;
  color: gray;
  text-align: left;
  padding-left: 40px;
  padding-right: 30px;
  margin: 0px;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: pre-line;
  cursor: default;

  @media (max-width: 760px) {
    padding-left: 30px;
    font-size: 0.9rem;
  }

  //   @media (min-width: 760px) {
  //     &:hover {
  //       text-decoration: ${(props) =>
    props.noUnderline ? "none" : "underline"};
  //     }
  //   }

  //   &:active {
  //     text-decoration: underline;
  //   }
`;
const SearchDiv = styled.div`
  width: calc(100% - 78px);
  //   max-width: 500px;
  margin-left: 39px;
  //   background: black;

  @media (max-width: 760px) {
    width: calc(100% - 58px);
    margin-left: 29px;
  }
`;
const Input = styled.input`
  box-sizing: border-box;
  height: 45px;
  padding: 10px 10px;
  width: 100%;
  //   max-width: 500px;
  font-size: 16px;
  font-family: San Francisco Regular;
  border: 2px solid black;
  border-radius: 10px;
  //   margin-left: 39px;
  -webkit-appearance: none;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  &:focus {
    outline: none;
    border-color: #d4af37;
  }

  //   @media (max-width: 760px) {
  //     width: 70%;
  //     margin-left: 29px;
  //   }
`;
const SearchResponse = styled.div`
  width: 100%;
  flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  position: static;
  gap: 10px;
  display: flex;
  padding-top: 20px;
  //   z-index: 99;
`;
const SearchBarDiv = styled.form`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
`;
const SearchButton = styled.button`
  all: unset;
  display: flex;
  box-sizing: border-box;
  padding: auto;
  // border-focus: #d4af37;
  text-decoration: none;
  font-family: San Francisco Bold;
  width: 60px;
  height: 45px;
  background-color: #efefef;
  border-radius: 10px;
  position: static;
  cursor: pointer;
  transition-duration: 0.25s;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 5px black;
    }
  }
`;
// put boxes around each product?
// const ProductImgDiv = styled.div`
//   display: flex;
//   justify-content: right;
//   gap: 5%;
//   // background: black;
// `;
// const ProductImg = styled.img`
//   height: 70px;
// `;

const ProductCompareDiv = styled.div`
  width: 90.25%;
  position: static;
  //   padding-top: 10px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (min-width: 760px) {
    flex-direction: row;
    width: 100%;
    gap: 20px;
  }
`;
const ProductCompareBackground = styled(Link)`
  display: block;
  text-decoration: none;
  color: black;
  width: 100%;
  height: 80px;
  border-radius: 15px;
  //   padding-top: 5px;
  position: static;
  transition-duration: 0.25s;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  background: linear-gradient(
    135deg,
    #efefef 0%,
    #efefef 45%,
    white 50%,
    #efefef 55%,
    #efefef 100%
  );
  background-size: 400% 400%;
  //   animation: ${GradientAnimation} 10s ease infinite;
  cursor: pointer;

  @media (min-width: 760px) {
    &:hover {
      box-shadow: 0px 0px 10px gray;
    }
  }
`;
const ProductCompareFront = styled.div`
  height: 70px;
  background-color: white;
  border-radius: 10px;
  position: static;
  margin: 5px;
`;
const ProductCompareOuterDiv = styled.div`
  display: flex;
  flex-direction: row;
  //   background: gray;
`;
const ProductCompareTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70px;
  width: 67%;
  //   margin-left: 0px;
  //   background: yellow;
`;
const CartDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ProductCompareBrand = styled.p`
  font-family: San Francisco Bold;
  font-size: 1rem;
  padding-left: 10px;
  line-height: 1.3rem;
  text-align: left;
  margin: 0px 0px;
  margin-bottom: 3px;
  line-height: 1.3rem;
`;
const ProductCompareFlavor = styled.p`
  font-family: San Francisco Regular;
  font-size: 0.9rem;
  text-align: left;
  color: gray;
  padding-left: 10px;
  margin: 0px 0px;
`;
const ProductCompareVS = styled.p`
    font-family: San Francisco Regular;
    font-size: 1rem;
    margin 3px 0px;
    color: black;
`;
const SearchResultCount = styled.p`
  font-family: San Francisco Regular;
  font-size: 11px;
  margin: 15px 0px 20px 0px;
  color: gray;
`;
const ProductImgDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  height: 70px;
  // background: yellow;
`;
const ProductImgOneDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60px;
`;
const ProductImgTwoDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 60px;
`;
const TestProductImgDiv = styled.div`
  display: flex;
  justify-content: center;
  // align-items: center;
  margin: 10px auto;
  width: 60px;
  //   background: yellow;
`;
const ProductImg = styled.img`
  display: block;
  height: 60px;
`;
const Table = styled.table`
  border-collapse: seperate;
  position: static;
  width: 80%;
  padding: 0px 0px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  font-family: San Francisco Regular;
  // table-layout: fixed;

  & span {
    float: left;
    margin: 0.5rem;
  }

  & th,
  td {
    border-style: dashed;
    border: 1px solid #ccc;
  }

  @media (max-width: 760px) {
    width: 90%;
  }
`;

const TableIngredient = styled.td`
  width: 60%;
`;

const TableValue = styled.td`
  width: 20%;
`;
const TableButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  //   background: yellow;

  @media (max-width: 760px) {
    width: 85%;
  }
`;
const TableButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;
const ScoopDiv = styled.div`
  width: 75%;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 5px;
  //   background: yellow;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  @media (max-width: 760px) {
    width: 85%;
  }
`;
const ScoopButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: black;
  // text-decoration: underline;

  &:hover {
    cursor: pointer;
  }

  &:active {
    text-decoration: underline;
  }
`;
const TableDefinition = styled.p`
  color: gray;
  font-size: 14px;
  margin: 10px 5px 10px 5px;
  font-family: San Francisco Regular;
`;
const UnderlinedSpan = styled.span`
  text-decoration: underline; /* Add underline to the span */
  color: inherit; /* Ensure text color is inherited */
  cursor: pointer;
`;

const NutritionTable = (props) => {
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  let ingredients;

  if (props.prod1.category === "preworkout") {
    ingredients = preIngredients;
  } else if (props.prod1.category === "proteinpowder") {
    ingredients = proteinIngredients;
  } else if (props.prod1.category === "electrolyte") {
    ingredients = electrolyteIngredients;
  } else if (props.prod1.category === "massgainer") {
    ingredients = massIngredients;
  }
  //   else if (props.prod1.category === "creatine") {
  //     ingredients = creatineIngredients;
  //   }

  const handleIngredientClick = (ingredient) => {
    if (ingredient === selectedIngredient) {
      return setSelectedIngredient(null);
    }
    setSelectedIngredient(ingredient);
  };

  const ppd = (prod) => {
    return (
      (parseFloat(prod.servings) * parseFloat(prod.protein)) /
      parseFloat(prod.price)
    ).toFixed(2);
  };

  const spd = (prod) => {
    return (
      (parseFloat(prod.servings) * parseFloat(prod.servingsize)) /
      parseFloat(prod.price)
    ).toFixed(2);
  };

  const cpd = (prod) => {
    return (
      (parseFloat(prod.servings) * parseFloat(prod.calories)) /
      parseFloat(prod.price)
    ).toFixed(2);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <span>
              {/* Maybe move to left side?*/}
              <img src={mainlogo} style={{ width: "60%" }} />
            </span>
          </th>
          {/* <th colspan="2">
            <span>Amount: </span>
          </th> */}
          <th>
            <TestProductImgDiv>
              <ProductImg src={props.prod1.imglink} />
            </TestProductImgDiv>
          </th>
          <th>
            <TestProductImgDiv>
              <ProductImg src={props.prod2.imglink} />
            </TestProductImgDiv>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <TableIngredient>
            <span>Price</span>
          </TableIngredient>
          <TableValue>
            <span>
              {props.prod1.region === "GB" ? "£" : "$"}
              {props.prod1.price}
            </span>
          </TableValue>
          <TableValue>
            <span>
              {props.prod2.region === "GB" ? "£" : "$"}
              {props.prod2.price}
            </span>
          </TableValue>
        </tr>
        {props.prod1.category === "massgainer" && (
          <tr>
            <TableIngredient>
              <span>Calories Per Dollar</span>
            </TableIngredient>
            <TableValue>
              <span>{cpd(props.prod1)}</span>
            </TableValue>
            <TableValue>
              <span>{cpd(props.prod2)}</span>
            </TableValue>
          </tr>
        )}
        {(props.prod1.category === "proteinpowder" ||
          props.prod1.category === "massgainer") && (
          <tr>
            <TableIngredient>
              <span>Protein Per Dollar</span>
            </TableIngredient>
            <TableValue>
              <span>{ppd(props.prod1)}g</span>
            </TableValue>
            <TableValue>
              <span>{ppd(props.prod2)}g</span>
            </TableValue>
          </tr>
        )}
        {props.prod1.category === "creatine" && (
          <tr>
            <TableIngredient>
              <span>Amount Per Dollar</span>
            </TableIngredient>
            <TableValue>
              <span>{spd(props.prod1)}g</span>
            </TableValue>
            <TableValue>
              <span>{spd(props.prod2)}g</span>
            </TableValue>
          </tr>
        )}
        {(props.prod1.category === "betaalanine" ||
          props.prod1.category === "citrulline") && (
          <tr>
            <TableIngredient>
              <span>Amount Per Dollar</span>
            </TableIngredient>
            <TableValue>
              <span>{spd(props.prod1)}mg</span>
            </TableValue>
            <TableValue>
              <span>{spd(props.prod2)}mg</span>
            </TableValue>
          </tr>
        )}
        <tr>
          <TableIngredient>
            <span>Servings</span>
          </TableIngredient>
          <TableValue>
            <span>
              {props.prod1.twoscoop && props.twoScoop
                ? parseFloat(props.prod1.servings) / 2
                : props.prod1.servings}
            </span>
          </TableValue>
          <TableValue>
            <span>
              {props.prod2.twoscoop && props.twoScoop
                ? parseFloat(props.prod2.servings) / 2
                : props.prod2.servings}
            </span>
          </TableValue>
        </tr>
        <tr>
          {props.prod1.category !== "creatine" &&
            props.prod1.category !== "betaalanine" &&
            props.prod1.category !== "citrulline" && (
              <TableIngredient>
                <span>Serving Size</span>
              </TableIngredient>
            )}
          {props.prod1.category === "preworkout" && (
            <>
              {props.prod1.subtitle.includes("(Liquid)") ? (
                <TableValue>
                  <span>
                    {props.prod1.twoscoop && props.twoScoop
                      ? parseFloat(props.prod1.servingsize) * 2
                      : props.prod1.servingsize}{" "}
                    fl. oz
                  </span>
                </TableValue>
              ) : (
                <TableValue>
                  <span>
                    {props.prod1.twoscoop && props.twoScoop
                      ? parseFloat(props.prod1.servingsize) * 2
                      : props.prod1.servingsize}
                    g
                  </span>
                </TableValue>
              )}

              {props.prod2.subtitle.includes("(Liquid)") ? (
                <TableValue>
                  <span>
                    {props.prod2.twoscoop && props.twoScoop
                      ? parseFloat(props.prod2.servingsize) * 2
                      : props.prod2.servingsize}{" "}
                    fl. oz
                  </span>
                </TableValue>
              ) : (
                <TableValue>
                  <span>
                    {props.prod2.twoscoop && props.twoScoop
                      ? parseFloat(props.prod2.servingsize) * 2
                      : props.prod2.servingsize}
                    g
                  </span>
                </TableValue>
              )}
            </>
          )}
          {props.prod1.category === "electrolyte" && (
            <>
              {props.prod1.type !== "powder" ? (
                <TableValue>
                  <span>
                    {props.prod1.servingsize} {props.prod1.type}
                    {props.prod1.servingsize !== "1" && "s"}
                  </span>
                </TableValue>
              ) : (
                <TableValue>
                  <span>{props.prod1.servingsize}g</span>
                </TableValue>
              )}
              {props.prod2.type !== "powder" ? (
                <TableValue>
                  <span>
                    {props.prod2.servingsize} {props.prod2.type}
                    {props.prod2.servingsize !== "1" && "s"}
                  </span>
                </TableValue>
              ) : (
                <TableValue>
                  <span>{props.prod2.servingsize}g</span>
                </TableValue>
              )}
            </>
          )}
          {(props.prod1.category === "proteinpowder" ||
            props.prod1.category === "massgainer") && (
            <>
              <TableValue>
                <span>{props.prod1.servingsize}g</span>
              </TableValue>
              <TableValue>
                <span>{props.prod2.servingsize}g</span>
              </TableValue>
            </>
          )}
        </tr>
        {(props.prod1.type === "Blend" || props.prod2.type === "Blend") && (
          <>
            <tr>
              <TableIngredient>
                <UnderlinedSpan onClick={() => handleIngredientClick("blend")}>
                  Whey Blend
                </UnderlinedSpan>
              </TableIngredient>
              <TableValue>
                <span>{props.prod1.type === "Blend" ? "100%" : "-"}</span>
              </TableValue>
              <TableValue>
                <span>{props.prod2.type === "Blend" ? "100%" : "-"}</span>
              </TableValue>
            </tr>
            {selectedIngredient && selectedIngredient === "blend" && (
              <tr>
                <td colSpan="3" style={{ border: "none" }}>
                  <TableDefinition>
                    {
                      proteinIngredients.find((item) => item.value === "blend")
                        .definition
                    }
                  </TableDefinition>
                </td>
              </tr>
            )}
          </>
        )}
        {(props.prod1.type === "Clear" || props.prod2.type === "Clear") && (
          <>
            <tr>
              <TableIngredient>
                <UnderlinedSpan onClick={() => handleIngredientClick("clear")}>
                  Clear Whey Isolate
                </UnderlinedSpan>
              </TableIngredient>
              <TableValue>
                <span>{props.prod1.type === "Clear" ? "100%" : "-"}</span>
              </TableValue>
              <TableValue>
                <span>{props.prod2.type === "Clear" ? "100%" : "-"}</span>
              </TableValue>
            </tr>
            {selectedIngredient && selectedIngredient === "clear" && (
              <tr>
                <td colSpan="3" style={{ border: "none" }}>
                  <TableDefinition>
                    {
                      proteinIngredients.find((item) => item.value === "clear")
                        .definition
                    }
                  </TableDefinition>
                </td>
              </tr>
            )}
          </>
        )}
        {(props.prod1.type === "Concentrate" ||
          props.prod2.type === "Concentrate") && (
          <>
            <tr>
              <TableIngredient>
                <UnderlinedSpan
                  onClick={() => handleIngredientClick("concentrate")}
                >
                  Whey Concentrate
                </UnderlinedSpan>
              </TableIngredient>
              <TableValue>
                <span>{props.prod1.type === "Concentrate" ? "100%" : "-"}</span>
              </TableValue>
              <TableValue>
                <span>{props.prod2.type === "Concentrate" ? "100%" : "-"}</span>
              </TableValue>
            </tr>
            {selectedIngredient && selectedIngredient === "concentrate" && (
              <tr>
                <td colSpan="3" style={{ border: "none" }}>
                  <TableDefinition>
                    {
                      proteinIngredients.find(
                        (item) => item.value === "concentrate"
                      ).definition
                    }
                  </TableDefinition>
                </td>
              </tr>
            )}
          </>
        )}
        {(props.prod1.type === "Isolate" || props.prod2.type === "Isolate") && (
          <>
            <tr>
              <TableIngredient>
                <UnderlinedSpan
                  onClick={() => handleIngredientClick("isolate")}
                >
                  Whey Isolate
                </UnderlinedSpan>
              </TableIngredient>
              <TableValue>
                <span>{props.prod1.type === "Isolate" ? "100%" : "-"}</span>
              </TableValue>
              <TableValue>
                <span>{props.prod2.type === "Isolate" ? "100%" : "-"}</span>
              </TableValue>
            </tr>
            {selectedIngredient && selectedIngredient === "isolate" && (
              <tr>
                <td colSpan="3" style={{ border: "none" }}>
                  <TableDefinition>
                    {
                      proteinIngredients.find(
                        (item) => item.value === "isolate"
                      ).definition
                    }
                  </TableDefinition>
                </td>
              </tr>
            )}
          </>
        )}
        {(props.prod1.type === "Vegan" || props.prod2.type === "Vegan") && (
          <>
            <tr>
              <TableIngredient>
                <UnderlinedSpan onClick={() => handleIngredientClick("vegan")}>
                  Vegan Protein
                </UnderlinedSpan>
              </TableIngredient>
              <TableValue>
                <span>{props.prod1.type === "Vegan" ? "100%" : "-"}</span>
              </TableValue>
              <TableValue>
                <span>{props.prod2.type === "Vegan" ? "100%" : "-"}</span>
              </TableValue>
            </tr>
            {selectedIngredient && selectedIngredient === "vegan" && (
              <tr>
                <td colSpan="3" style={{ border: "none" }}>
                  <TableDefinition>
                    {
                      proteinIngredients.find((item) => item.value === "vegan")
                        .definition
                    }
                  </TableDefinition>
                </td>
              </tr>
            )}
          </>
        )}
        {props.prod1.category === "creatine" && (
          <>
            <tr>
              <TableIngredient>
                <UnderlinedSpan
                  onClick={() => handleIngredientClick(props.prod1.type)}
                >
                  Creatine ({props.prod1.type})
                </UnderlinedSpan>
              </TableIngredient>
              <TableValue>
                <span>{props.prod1.servingsize}g</span>
              </TableValue>
              <TableValue>
                <span>
                  {props.prod2.type === props.prod1.type
                    ? props.prod2.servingsize + "g"
                    : "-"}
                </span>
              </TableValue>
            </tr>
          </>
        )}
        {props.prod1.category === "creatine" &&
          props.prod1.type !== props.prod2.type && (
            <>
              <tr>
                <TableIngredient>
                  <UnderlinedSpan
                    onClick={() => handleIngredientClick(props.prod1.type)}
                  >
                    Creatine ({props.prod2.type})
                  </UnderlinedSpan>
                </TableIngredient>
                <TableValue>
                  <span>
                    {props.prod2.type === props.prod1.type
                      ? props.prod1.servingsize + "g"
                      : "-"}
                  </span>
                </TableValue>
                <TableValue>
                  <span>{props.prod2.servingsize + "g"}</span>
                </TableValue>
              </tr>
            </>
          )}
        {props.prod1.category === "creatine" &&
          selectedIngredient &&
          selectedIngredient === "Monohydrate" && (
            <tr>
              <td colSpan="3" style={{ border: "none" }}>
                <TableDefinition>
                  {
                    creatineIngredients.find(
                      (item) => item.value === "monohydrate"
                    ).definition
                  }
                </TableDefinition>
              </td>
            </tr>
          )}
        {props.prod1.category === "creatine" &&
          selectedIngredient &&
          selectedIngredient === "Micronized Monohydrate" && (
            <tr>
              <td colSpan="3" style={{ border: "none" }}>
                <TableDefinition>
                  {
                    creatineIngredients.find(
                      (item) => item.value === "micronized"
                    ).definition
                  }
                </TableDefinition>
              </td>
            </tr>
          )}
        {props.prod1.category === "creatine" &&
          selectedIngredient &&
          selectedIngredient === "HCl" && (
            <tr>
              <td colSpan="3" style={{ border: "none" }}>
                <TableDefinition>
                  {
                    creatineIngredients.find((item) => item.value === "hcl")
                      .definition
                  }
                </TableDefinition>
              </td>
            </tr>
          )}
        {props.prod1.category === "betaalanine" && (
          <>
            <tr>
              <TableIngredient>
                <UnderlinedSpan
                  onClick={() => handleIngredientClick(props.prod1.type)}
                >
                  Beta Alanine
                </UnderlinedSpan>
              </TableIngredient>
              <TableValue>
                <span>{props.prod1.servingsize}mg</span>
              </TableValue>
              <TableValue>
                <span>{props.prod1.servingsize}mg</span>
              </TableValue>
            </tr>
          </>
        )}
        {props.prod1.category === "betaalanine" &&
          selectedIngredient &&
          selectedIngredient === "betaalanine" && (
            <tr>
              <td colSpan="3" style={{ border: "none" }}>
                <TableDefinition>
                  {
                    preIngredients.find((item) => item.value === "betaalanine")
                      .definition
                  }
                </TableDefinition>
              </td>
            </tr>
          )}
        {props.prod1.category === "citrulline" && (
          <>
            <tr>
              <TableIngredient>
                <UnderlinedSpan
                  onClick={() => handleIngredientClick(props.prod1.type)}
                >
                  Citrulline
                </UnderlinedSpan>
              </TableIngredient>
              <TableValue>
                <span>{props.prod1.servingsize}mg</span>
              </TableValue>
              <TableValue>
                <span>{props.prod1.servingsize}mg</span>
              </TableValue>
            </tr>
          </>
        )}
        {props.prod1.category === "citrulline" &&
          selectedIngredient &&
          selectedIngredient === "citrulline" && (
            <tr>
              <td colSpan="3" style={{ border: "none" }}>
                <TableDefinition>
                  {
                    preIngredients.find((item) => item.value === "citrulline")
                      .definition
                  }
                </TableDefinition>
              </td>
            </tr>
          )}
        {ingredients &&
          ingredients.map((ingredient) => {
            const { value, label, unit, definition } = ingredient;

            if (
              value === "blend" ||
              value === "concentrate" ||
              value === "clear" ||
              value === "isolate" ||
              value === "vegan"
            ) {
              return null;
            }

            if (
              (props.prod1[value] !== null || props.prod2[value] !== null) &&
              value !== "hydromax" &&
              value !== "glycersize" &&
              value !== "glycerpump" &&
              value !== "hydropump" &&
              value !== "hydroprime" &&
              value !== "alphazone" &&
              value !== "alphasize" &&
              value !== "carnosyn" &&
              value !== "creaclear"
            ) {
              return (
                <>
                  <tr key={value}>
                    <TableIngredient>
                      {definition ? (
                        <UnderlinedSpan
                          onClick={() => handleIngredientClick(ingredient)}
                        >
                          {label}
                        </UnderlinedSpan>
                      ) : (
                        <span>{label}</span>
                      )}
                    </TableIngredient>
                    <TableValue>
                      <span>
                        {props.prod1.twoscoop &&
                        props.prod1[value] &&
                        props.twoScoop
                          ? parseFloat(props.prod1[value]) * 2
                          : props.prod1[value]}
                        {props.prod1[value] ? unit : "-"}
                      </span>
                    </TableValue>
                    <TableValue>
                      <span>
                        {props.prod2.twoscoop &&
                        props.prod2[value] &&
                        props.twoScoop
                          ? parseFloat(props.prod2[value]) * 2
                          : props.prod2[value]}
                        {props.prod2[value] ? unit : "-"}
                      </span>
                    </TableValue>
                  </tr>
                  {selectedIngredient && selectedIngredient.value === value && (
                    <tr>
                      <td colSpan="3" style={{ border: "none" }}>
                        <TableDefinition>{definition}</TableDefinition>
                      </td>
                    </tr>
                  )}
                </>
              );
            }
            return null;
          })}
      </tbody>
    </Table>
  );
};

const ProductCompareHeader = ({ item, number, link }) => {
  const compareSubmaker = (item) => {
    let subtitle;

    if (item.category === "preworkout" || item.category === "massgainer") {
      subtitle = item.subtitle;
    }

    if (item.category === "electrolyte") {
      if (item.type === "packet") {
        subtitle = "Electrolyte Packets";
      }
      if (item.type === "powder") {
        subtitle = "Electrolyte Powder";
      }
      if (item.type === "capsule") {
        subtitle = "Electrolyte Capsules";
      }
      if (item.type === "tablet") {
        subtitle = "Electrolyte Tablets";
      }
    }

    if (item.category === "proteinpowder") {
      if (item.type === "Vegan") {
        subtitle = "Vegan";
      } else if (item.type === "Clear") {
        subtitle = "Clear Whey Isolate";
      } else {
        subtitle = "Whey " + item.type;
      }
    }

    if (item.category === "betaalanine") {
      if (item.type === "powder") {
        subtitle = "Beta Alanine Powder";
      }
      if (item.type === "capsule") {
        subtitle = "Beta Alanine Capsules";
      }
    }

    if (item.category === "citrulline") {
      if (item.type === "powder") {
        subtitle = "Citrulline Powder";
      }
      if (item.type === "capsule") {
        subtitle = "Citrulline Capsules";
      }
    }

    if (item.category === "creatine") {
      if (item.type === "Micronized Monohydrate") {
        subtitle = "Micronized Monohydrate";
      } else {
        subtitle = item.type;
      }
    }

    return subtitle;
  };

  return (
    <ProductCompareBackground
      to={link}
      {...(link ? { target: "_blank", rel: "noopener noreferrer" } : {})}
    >
      <ProductCompareFront>
        <ProductCompareOuterDiv>
          <ProductImgDiv>
            <TestProductImgDiv>
              <ProductImg src={item ? item.imglink : defaultproduct} />
            </TestProductImgDiv>
            {/* <ProductImgTwoDiv>
              <ProductImg src={itemTwo ? itemTwo.imglink : defaultproduct} />
            </ProductImgTwoDiv> */}
          </ProductImgDiv>
          <ProductCompareTitleDiv>
            <div>
              <ProductCompareBrand>
                {item ? item.brand : "Product " + number}
              </ProductCompareBrand>
              <ProductCompareFlavor>
                {item ? compareSubmaker(item) : "Select from search."}
              </ProductCompareFlavor>
            </div>
            {/* <ProductCompareVS>vs</ProductCompareVS>
            <div style={{ marginBottom: "13px" }}>
              <ProductCompareBrand>
                {itemTwo ? itemTwo.brand : "Product Two"}
              </ProductCompareBrand>
              <ProductCompareFlavor>
                {itemTwo ? compareSubmaker(itemTwo) : "Select from search."}
              </ProductCompareFlavor>
            </div> */}
          </ProductCompareTitleDiv>
          {item && (
            <CartDiv>
              <ExternalSVG color={"black"} />
            </CartDiv>
          )}
        </ProductCompareOuterDiv>
      </ProductCompareFront>
    </ProductCompareBackground>
  );
};

const Compare = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const [productData, setProductData] = useState([]);
  const [productOne, setProductOne] = useState();
  const [productTwo, setProductTwo] = useState();
  const [productOneData, setProductOneData] = useState([]);
  const [productTwoData, setProductTwoData] = useState([]);
  const [scoopToggle, setScoopToggle] = useState(false);
  const [emptySearch, setEmptySearch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const createProductCall = (product) => {
    let link;
    if (product.category === "preworkout") {
      link =
        "/api/product-group?category=preworkout&brand=" +
        product.brand +
        "&subtitle=" +
        product.subtitle +
        "&region=" +
        props.userCountry;
    } else if (product.category === "proteinpowder") {
      link =
        "/api/product-group?category=proteinpowder&brand=" +
        product.brand +
        "&type=" +
        product.subtitle +
        "&region=" +
        props.userCountry;
    } else if (product.category === "creatine") {
      link =
        "/api/product-group?category=creatine&brand=" +
        product.brand +
        "&type=" +
        product.subtitle +
        "&patent=" +
        product.patent +
        "&region=" +
        props.userCountry;
    } else if (
      product.category === "citrulline" ||
      product.category === "betaalanine" ||
      product.category === "electrolyte"
    ) {
      link =
        "/api/product-group?category=" +
        product.category +
        "&brand=" +
        product.brand +
        "&type=" +
        product.subtitle +
        "&region=" +
        props.userCountry;
    } else if (product.category === "massgainer") {
      link =
        "/api/product-group?category=massgainer&brand=" +
        product.brand +
        "&subtitle=" +
        product.subtitle +
        "&region=" +
        props.userCountry;
    }

    return link;
  };

  const getProductData = async (item, product) => {
    try {
      const response = await fetch(createProductCall(item), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (response.ok) {
        // setting product data for search
        if (product === 1) {
          if (
            item.category === "betaalanine" ||
            item.category === "citrulline"
          ) {
            setProductOneData(
              data.filter(
                (i) =>
                  (i.flavor === null ? "null" : i.flavor) === item.flavor &&
                  i.servings === parseFloat(item.servings)
              )
            );
          } else {
            setProductOneData(
              data.filter(
                (i) => i.flavor === item.flavor && i.servings === item.servings
              )
            );
          }
          // function to create full ingredient list
        } else if (product === 2) {
          if (
            item.category === "betaalanine" ||
            item.category === "citrulline"
          ) {
            setProductTwoData(
              data.filter(
                (i) =>
                  (i.flavor === null ? "null" : i.flavor) === item.flavor &&
                  i.servings === parseFloat(item.servings)
              )
            );
          } else {
            setProductTwoData(
              data.filter(
                (i) => i.flavor === item.flavor && i.servings === item.servings
              )
            );
          }
          // function to create full ingredient list
        }
      } else {
        // getting product data failed
      }
    } catch (error) {
      // getting product data failed
      //   console.error("Error during user check:", error);
    }
  };

  const getProducts = async () => {
    if (message.length === 0) {
      setProductData([]);
      return;
    }
    try {
      setSearchLoading(true);
      const response = await fetch(
        productOne
          ? "/api/product-search?q=" +
              message +
              "&region=" +
              props.userCountry +
              "&category=" +
              productOne.category
          : "/api/product-search?q=" + message + "&region=" + props.userCountry,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        // setting product data for search
        setSearchLoading(false);
        if (data.length === 0) {
          setProductData(data);
          setEmptySearch(true);
        } else {
          setProductData(data);
          setEmptySearch(false);
        }
      } else {
        // getting product data failed
        setSearchLoading(false);
      }
    } catch (error) {
      setSearchLoading(false);
      // getting product data failed
      //   console.error("Error during user check:", error);
    }
  };

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    getProducts();
  };

  const setProducts = (item) => {
    if (!productOne) {
      setProductOne(item);
      setMessage("");
    } else if (!productTwo) {
      setProductTwo(item);
      setMessage("");
    } else if (productOne && productTwo) {
      setProductTwo(item);
      setMessage("");
    }
  };

  const ScoopChange = () => {
    setScoopToggle(!scoopToggle);
  };

  const ClearSelections = () => {
    setProductOne();
    setProductOneData([]);
    setProductTwo();
    setProductTwoData([]);
    setSearchParams(new URLSearchParams(), { replace: true });
  };

  useEffect(() => {
    if (searchParams) {
      const category1 = searchParams.get("category1");
      const brand1 = searchParams.get("brand1");
      const subtitle1 = searchParams.get("subtitle1");
      const servings1 = searchParams.get("servings1");
      const flavor1 = searchParams.get("flavor1");
      const patent1 = searchParams.get("patent1");

      const category2 = searchParams.get("category2");
      const brand2 = searchParams.get("brand2");
      const subtitle2 = searchParams.get("subtitle2");
      const servings2 = searchParams.get("servings2");
      const flavor2 = searchParams.get("flavor2");
      const patent2 = searchParams.get("patent2");

      // Update only if valid data is available
      if (category1 || brand1 || subtitle1 || patent1) {
        setProductOne({
          category: category1,
          brand: brand1,
          subtitle: subtitle1,
          servings: servings1,
          flavor: flavor1,
          patent: patent1,
        });
      }

      if (category2 || brand2 || subtitle2 || patent2) {
        setProductTwo({
          category: category2,
          brand: brand2,
          subtitle: subtitle2,
          servings: servings2,
          flavor: flavor2,
          patent: patent2,
        });
      }
    }
  }, [searchParams]);

  useEffect(() => {
    console.log("Product One: ", productOne);
    console.log("Product Two: ", productTwo);

    const params = new URLSearchParams();

    if (productOne) {
      params.append("category1", productOne.category);
      params.append("brand1", productOne.brand);
      params.append("subtitle1", productOne.subtitle);
      params.append("servings1", productOne.servings);
      params.append("flavor1", productOne.flavor);
      if (productOne.patent) params.append("patent1", productOne.patent);

      getProductData(productOne, 1); // move to useEffect? (so i can set params and have it triggered)
      setProductData([]);
    }
    if (productTwo) {
      params.append("category2", productTwo.category);
      params.append("brand2", productTwo.brand);
      params.append("subtitle2", productTwo.subtitle);
      params.append("servings2", productTwo.servings);
      params.append("flavor2", productTwo.flavor);
      if (productTwo.patent) params.append("patent2", productTwo.patent);

      getProductData(productTwo, 2); // move to useEffect? (so i can set params and have it triggered)
      setProductData([]);
    }

    if (productOne || productTwo) {
      setSearchParams(params, { replace: true });
    }
  }, [productOne, productTwo]);

  useEffect(() => {
    console.log("Product One Search Data: ", productOneData);
    console.log("Product Two Search Data: ", productTwoData);

    if (productOneData.length > 0 && productTwoData.length > 0) {
      if (productOneData[0].twoscoop || productTwoData[0].twoscoop) {
        setScoopToggle(true);
      } else {
        setScoopToggle(false);
      }
    }
  }, [productOneData, productTwoData]);

  return (
    <DesktopCenter>
      <Helmet>
        <title>Compare Supplement Products</title>
        <meta
          name="description"
          content="Compare popular supplement products ingredients and price side by side."
        />
        <meta property="og:title" content="Compare Supplement Products" />
        <meta
          property="og:description"
          content="Compare popular supplement products ingredients and price side by side."
        />
      </Helmet>
      <ResponseDiv style={{ paddingTop: "25px" }}>
        <ResponseTitle>
          <ReactTyped
            strings={["Compare Products"]}
            typeSpeed={30}
            showCursor={false}
          />
        </ResponseTitle>
        <ResponseSubtitle
        //   onClick={() => handlePopUp(["micronized"], creatineIngredients)}
        >
          {/* <SvgWrapper>
            <IngredientEyeSVG />
          </SvgWrapper> */}
          {"Directly compare product ingredients."}
        </ResponseSubtitle>
        <SearchDiv>
          <SearchBarDiv>
            <Input
              type="text"
              value={message}
              onChange={handleInputChange}
              placeholder="Search products..."
            />

            <SearchButton onClick={handleFormSubmit} type="submit">
              {!searchLoading ? (
                <RightArrowSVG color="black" />
              ) : (
                <CircularProgress color="inherit" size={24} />
              )}
            </SearchButton>
          </SearchBarDiv>
          <center>
            <SearchResponse>
              {productData.length > 0 &&
                productData.map((item, index) => (
                  <BrandListSearch
                    item={item}
                    onClick={() => setProducts(item)}
                    ticker={false}
                    loading={false}
                    ai={true}
                    key={index}
                  />
                ))}
            </SearchResponse>
            {(productData.length > 0 || emptySearch) && (
              <SearchResultCount
                style={emptySearch ? { marginTop: "0px" } : {}}
              >
                {productData.length} Search Result
                {productData.length !== 1 && "s"}
                {productData.length === 50 && " (Limit)"}
              </SearchResultCount>
            )}
          </center>
        </SearchDiv>
        <ProductCompareDiv>
          <ProductCompareHeader
            item={productOneData[0]} // causing bug (imglink is not set for productOne)
            number={"One"}
            link={
              productOne && productOneData.length > 0
                ? productOneData[0].shoplink
                : ""
            }
          />
          <ProductCompareVS>vs</ProductCompareVS>
          <ProductCompareHeader
            item={productTwoData[0]}
            number={"Two"}
            link={
              productTwo && productTwoData.length > 0
                ? productTwoData[0].shoplink
                : ""
            }
          />
        </ProductCompareDiv>
        <div style={{ padding: "20px 0px" }}>
          <TableButtonDiv>
            {(productOneData.length > 0 || productTwoData.length > 0) && (
              <TableButton>
                <TableClearSVG />
                <ScoopButton onClick={ClearSelections}>Clear</ScoopButton>
              </TableButton>
            )}
            {productOneData.length > 0 &&
              productTwoData.length > 0 &&
              (productOneData[0].twoscoop || productTwoData[0].twoscoop) && (
                <TableButton>
                  <EyeSVG />
                  <ScoopButton onClick={ScoopChange}>
                    {scoopToggle ? "2 Scoops" : "1 Scoop"}
                  </ScoopButton>
                </TableButton>
              )}
          </TableButtonDiv>
          {productOneData.length > 0 && productTwoData.length > 0 && (
            <NutritionTable
              prod1={productOneData[0]}
              prod2={productTwoData[0]}
              twoScoop={scoopToggle}
            />
          )}
        </div>
        {/* </center> */}
      </ResponseDiv>
    </DesktopCenter>
  );
};

export default Compare;
