import React from "react";
import styled from "styled-components";
import AppLogo from "../images/supphead512.png";
import { Helmet } from "react-helmet-async";

const ProfileDiv = styled.div`
  display: flex;
  justify-content: center;
  padding: 25px 0px 50px 0px;
  width: 100%;

  @media (max-width: 760px) {
    align-items: center;
    justify-content: start;
    width: 85%;
    padding: 25px 0px 25px 0px;
  }
`;
const InfoDiv = styled.div`
  width: 40%;

  @media (max-width: 760px) {
    width: auto;
  }
`;
const ProfilePic = styled.img`
  --size: 10%;

  display: block;
  width: var(--size);
  height: var(--size);
  border-radius: calc(100% / 2);
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (max-width: 760px) {
    --size: 30%;
  }
`;
const Logo = styled.img`
  --size: 10%;

  display: block;
  width: var(--size);
  height: var(--size);
  border: 1px solid gray;
  border-radius: calc(100% * (10 / 57));
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

  @media (max-width: 760px) {
    --size: 30%;
  }
`;
const Title = styled.p`
  font-size: 1.75rem;
  font-family: San Francisco Bold;
  padding-left: 50px;
  text-align: left;
  margin: 0px;

  @media (max-width: 760px) {
    font-size: 1.25rem;
    padding-left: 25px;
  }
`;
const Subtitle = styled.p`
  font-size: 1rem;
  text-align: left;
  color: gray;
  padding-left: 50px;
  margin-top: 5px;
  font-family: San Francisco Regular;
  @media (max-width: 760px) {
    padding-left: 25px;
  }
`;
const Text = styled.p`
  padding-left: 50px;
  text-align: left;

  @media (max-width: 760px) {
    display: none;
  }
`;
const MobileText = styled.p`
  padding-bottom: 25px;
  margin: 0px;
  width: 85%;
  text-align: left;

  @media (min-width: 760px) {
    display: none;
  }
`;

const About = () => {
  return (
    <center>
      <Helmet>
        <title>About</title>
        <meta
          name="description"
          content="What is SUPPHEAD? Read about it's inception here."
        />
        <meta property="og:title" content="About" />
        <meta
          property="og:description"
          content="What is SUPPHEAD? Read about it's inception here."
        />
      </Helmet>
      <ProfileDiv>
        <Logo src={AppLogo} />
        <InfoDiv>
          <Title>SUPPHEAD</Title>
          <Subtitle>v1.8.0</Subtitle>
          <Text>
            SUPPHEAD is a supplement ranking tool that serves the rapidly
            growing industry of fitness, weightlifting, and bodybuilding. Users
            are able to quickly compare and identify the highest value products
            based on the ingredients/filters most important to them. No
            marketing tricks, just rankings based on actual ingredients, serving
            sizes, and cost.
          </Text>
        </InfoDiv>
      </ProfileDiv>
      <MobileText>
        SUPPHEAD is a supplement ranking tool that serves the rapidly growing
        industry of fitness, weightlifting, and bodybuilding. Users are able to
        quickly compare and identify the highest value products based on the
        ingredients/filters most important to them. No marketing tricks, just
        rankings based on actual ingredients, serving sizes, and cost.
      </MobileText>
      <ProfileDiv>
        <ProfilePic src="https://i.imgur.com/inhbXpql.jpg" />
        <InfoDiv>
          <Title>Parker Stevenson</Title>
          <Subtitle>Developer</Subtitle>
          <Text>
            I started lifting weights in high school during COVID, but decided
            to take it to a new level over the last couple years while studying
            Computer Science in college. As part of that process, I began to
            educate myself on supplements, but found the number of options and
            amount of information to be overwhelming. I built this tool for
            myself as a way to automate the collection and comparison of
            products to find the highest value. I quickly saw the value it could
            bring to others, so I built this website. Please feel free to email
            feature suggestions, product/brand requests, or any questions you
            have to: contact@supphead.com
          </Text>
        </InfoDiv>
      </ProfileDiv>
      <MobileText>
        I started lifting weights in high school during COVID, but decided to
        take it to a new level over the last couple years while studying
        Computer Science in college. As part of that process, I began to educate
        myself on supplements, but found the number of options and amount of
        information to be overwhelming. I built this tool for myself as a way to
        automate the collection and comparison of products to find the highest
        value. I quickly saw the value it could bring to others, so I built this
        website. Please feel free to email feature suggestions, product/brand
        requests, or any questions you have to: contact@supphead.com
      </MobileText>
    </center>
  );
};

export default About;
