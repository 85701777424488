import React, { useState, useEffect } from "react";
import styled from "styled-components";
import menulogo from "../images/hamburgermenu.png";
import { Link, useLocation } from "react-router-dom";
import podiumglyph from "../images/podiumglyph.png";
import user from "../images/user.png";
import suppheadlogo from "../images/supphead.png";
import mainlogo from "../images/suppheadgradient.png";

const NavBarContainer = styled.nav`
  background-color: white;
  font-family: San Francisco Regular;
  overflow: hidden;
  color: white;
  transition-duration: 0.25s;
  height: 75px;
  //background: #efefef;

  @media (max-width: 760px) {
    height: ${(props) =>
      props.open ? "470px" : "75px"}; // 15px less than dividing by items
    border-bottom: solid #efefef 1px;
  }
`;
const NavBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  height: 75px;
`;
const NavBarDropdown = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  height: 395px;
  background: #efefef;
  // margin-bottom: 20px;

  @media (min-width: 760px) {
    display: none;
  }
`;
const NavLogo = styled.img`
  height: 20px;
  padding: 30px;
  width: auto;
  cursor: pointer;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  -webkit-tap-highlight-color: transparent; /* Disable tap highlight */
  -webkit-touch-callout: none; /* Disable touch callout */
`;
const NavMenu = styled.img`
  height: 20px;
  padding: 30px;
  width: auto;
  cursor: pointer;

  @media (min-width: 760px) {
    display: none;
  }
`;
const NavLinkDivDesktop = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px;
  gap: 20px;

  @media (max-width: 760px) {
    display: none;
  }
`;
const NavLinkDivMobile = styled.div`
  display: flex;
  flex-direction: column;
  // padding-top: 10px;
  // padding-bottom: 10px;
  padding-left: 35px;
  gap: 20px;

  @media (min-width: 760px) {
    display: none;
  }
`;
const NavLinks = styled(Link)`
  text-decoration: none;
  color: black;

  &:hover {
    text-decoration: underline;
  }
`;
const Text = styled.p`
  font-size: 14px;
  margin: auto;
`;

const NavBar = (props) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const img1 = new Image();
    const img2 = new Image();
    img1.src = user;
    img2.src = suppheadlogo;
  }, []);

  const triggerOpen = () => {
    setOpen((prevState) => {
      return !prevState;
    });
  };

  return (
    <NavBarContainer open={open}>
      <NavBarContent>
        {pathname === "/" ? (
          <Link to="/account">
            <NavLogo src={user} isVisible={pathname === "/"} />
          </Link>
        ) : (
          <Link to="/" onClick={open && triggerOpen}>
            <NavLogo src={suppheadlogo} isVisible={pathname !== "/"} />
          </Link>
        )}
        <NavMenu src={menulogo} onClick={triggerOpen} />
        <NavLinkDivDesktop>
          <NavLinks to="/betaalanine/examples">
            <Text>Beta Alanine</Text>
          </NavLinks>
          <NavLinks to="/citrulline/examples">
            <Text>Citrulline</Text>
          </NavLinks>
          <NavLinks to="/creatine/examples">
            <Text>Creatine</Text>
          </NavLinks>
          <NavLinks to="/electrolyte/examples">
            <Text>Electrolytes</Text>
          </NavLinks>
          <NavLinks to="/massgainer">
            <Text>Mass Gainer</Text>
          </NavLinks>
          <NavLinks to="/preworkout/examples">
            <Text>Pre-Workout</Text>
          </NavLinks>
          <NavLinks to="/proteinpowder/examples">
            <Text>Protein Powder</Text>
          </NavLinks>
          <NavLinks to="/compare">
            <Text>Compare</Text>
          </NavLinks>
          <NavLinks to="/about">
            <Text>About</Text>
          </NavLinks>
          <NavLinks to="/account">
            {" "}
            {/* swap to account when logged in*/}
            {!props.isLoggedIn ? (
              <Text>Login or Sign Up</Text>
            ) : (
              <Text>Account</Text>
            )}
          </NavLinks>
          {/* <NavLinks href="/login">
            <Text>Log In</Text>
          </NavLinks>
          <NavLinks href="/signup">
            <Text>Sign Up</Text>
          </NavLinks> */}
        </NavLinkDivDesktop>
      </NavBarContent>
      <NavBarDropdown>
        <NavLinkDivMobile>
          <NavLinks to="/betaalanine/examples" onClick={triggerOpen}>
            <Text>Beta Alanine</Text>
          </NavLinks>
          <NavLinks to="/citrulline/examples" onClick={triggerOpen}>
            <Text>Citrulline</Text>
          </NavLinks>
          <NavLinks to="/creatine/examples" onClick={triggerOpen}>
            <Text>Creatine</Text>
          </NavLinks>
          <NavLinks to="/electrolyte/examples" onClick={triggerOpen}>
            <Text>Electrolytes</Text>
          </NavLinks>
          <NavLinks to="/massgainer" onClick={triggerOpen}>
            <Text>Mass Gainer</Text>
          </NavLinks>
          <NavLinks to="/preworkout/examples" onClick={triggerOpen}>
            <Text>Pre-Workout</Text>
          </NavLinks>
          <NavLinks to="/proteinpowder/examples" onClick={triggerOpen}>
            <Text>Protein Powder</Text>
          </NavLinks>
          <NavLinks to="/compare" onClick={triggerOpen}>
            <Text>Compare</Text>
          </NavLinks>
          <NavLinks to="/about" onClick={triggerOpen}>
            <Text>About</Text>
          </NavLinks>
          <NavLinks to="/account" onClick={triggerOpen}>
            {" "}
            {/* swap to account when logged in*/}
            {!props.isLoggedIn ? (
              <Text>Login or Sign Up</Text>
            ) : (
              <Text>Account</Text>
            )}
          </NavLinks>
          {/* <NavLinks href="/login">
            <Text>Log In</Text>
          </NavLinks>
          <NavLinks href="/signup">
            <Text>Sign Up</Text>
          </NavLinks> */}
        </NavLinkDivMobile>
      </NavBarDropdown>
    </NavBarContainer>
  );
};

export default NavBar;
