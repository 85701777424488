import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Fonts from "./fonts/Fonts";
import Loading from "./components/Loading";
import Error from "./pages/Error";
import ProteinPowderProduct from "./pages/ProteinPowderProduct";
import CreatineFetchFilter from "./pages/CreatineFetchFilter";
import BetaFetchFilter from "./pages/BetaFetchFilter";
import CitrullineFetchFilter from "./pages/CitrullineFetchFilter";
import ProteinFetchFilter from "./pages/ProteinFetchFilter";
import PreFetchFilter from "./pages/PreFetchFilter";
import BetaProduct from "./pages/BetaProduct";
import CitrullineProduct from "./pages/CitrullineProduct";
import CreatineProduct from "./pages/CreatineProduct";
import PreworkoutProduct from "./pages/PreworkoutProduct";
import Account from "./pages/Account";
import styled from "styled-components";
import About from "./pages/About";
import AI from "./pages/Home";
import { pageview, initializeGA } from "./custom/analytics";
import ElectrolyteFilter from "./pages/ElectrolyteFetchFilter";
import ElectrolyteProduct from "./pages/ElectrolyteProduct";
import MassFilter from "./pages/MassFetchFilter";
import MassProduct from "./pages/MassProduct";
import PreExample from "./pages/PreExample";
import ProteinExample from "./pages/ProteinExample";
import ElectrolyteExample from "./pages/ElectrolyteExample";
import CreatineExample from "./pages/CreatineExample";
import CitrullineExample from "./pages/CitrullineExample";
import BetaExample from "./pages/BetaExample";
import Compare from "./pages/Compare";
import { HelmetProvider } from "react-helmet-async";

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;
const ContentWrap = styled.div`
  padding-bottom: 100px;
`;

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    pageview(location.pathname + location.search);
  }, [location]);
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [apiStatus, setApiStatus] = useState(200);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userfname, setUserfname] = useState("");
  const [userCountry, setUserCountry] = useState(
    process.env.REACT_APP_ENV === "development" ? "US" : null
  ); // defaults to US products
  const [preFilter, setPreFilter] = useState([]);
  const [preFilterLink, setPreFilterLink] = useState(null);
  const [proteinFilter, setProteinFilter] = useState([]);
  const [proteinFilterLink, setProteinFilterLink] = useState(null);
  const [electrolyteFilter, setElectrolyteFilter] = useState([]);
  const [electrolyteFilterLink, setElectrolyteFilterLink] = useState(null);
  const [creatineFilter, setCreatineFilter] = useState([]);
  const [creatineFilterLink, setCreatineFilterLink] = useState(null);
  const [citrullineFilter, setCitrullineFilter] = useState([]);
  const [citrullineFilterLink, setCitrullineFilterLink] = useState(null);
  const [betaFilter, setBetaFilter] = useState([]);
  const [betaFilterLink, setBetaFilterLink] = useState(null);
  const [massFilter, setMassFilter] = useState([]);
  const [massFilterLink, setMassFilterLink] = useState(null);

  useEffect(() => {
    initializeGA();
    const checkUser = async () => {
      try {
        const response = await fetch(`/api/userCheck`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const userData = await response.json();
        if (response.ok) {
          // console.log(data);
          // user doesn't exist anymore in db
          setLoggedIn(true);
          setVerified(userData.verified);
          setUserEmail(userData.email);
          setUserfname(userData.fname);
          setUserCountry(userData.country);
        } else {
          setUserCountry(userData.country);
          // console.log(userData.country);
          try {
            const response = await fetch("/api/logout", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
            });

            if (response.ok) {
              const data = await response.json();
              // Handle successful response
              // console.log(data)
            } else {
              // Handle server errors
              const errorData = await response.json();

              console.log(errorData.error || "Logout failed");
            }
          } catch (error) {
            console.error("Error during logout:", error);
          }
          setLoggedIn(false);
          setVerified(false);
          setUserEmail("");
          setUserfname("");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error during user check:", error);
      }
    };
    setLoading(true);
    if (process.env.REACT_APP_ENV === "development") {
      setLoggedIn(true);
      setVerified(true);
      setLoading(false);
      // checkUser(); // comment out
    } else {
      checkUser();
    }
  }, []);

  usePageTracking(); // Google Analytics

  // console.log(userCountry);

  return (
    <>
      <HelmetProvider>
        <Fonts />
        <PageContainer>
          <ContentWrap>
            <NavBar loading={loading} isLoggedIn={isLoggedIn} />
            <Routes>
              <Route path="/about" element={<About />} />
              <Route
                path="/account"
                element={
                  <Account
                    isLoggedIn={isLoggedIn}
                    isVerified={isVerified}
                    userEmail={userEmail}
                    userfname={userfname}
                    userCountry={userCountry}
                    setUserCountry={setUserCountry}
                    setLoggedIn={setLoggedIn}
                    setVerified={setVerified}
                    setUserEmail={setUserEmail}
                    setUserfname={setUserfname}
                  />
                }
              />
              <Route
                path="/"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <AI
                      apiStatus={apiStatus}
                      isLoggedIn={isLoggedIn}
                      isVerified={isVerified}
                      userCountry={userCountry}
                      setPreFilter={setPreFilter}
                      setPreFilterLink={setPreFilterLink}
                      setProteinFilter={setProteinFilter}
                      setProteinFilterLink={setProteinFilterLink}
                      setElectrolyteFilter={setElectrolyteFilter}
                      setElectrolyteFilterLink={setElectrolyteFilterLink}
                      setCreatineFilter={setCreatineFilter}
                      setCreatineFilterLink={setCreatineFilterLink}
                      setCitrullineFilter={setCitrullineFilter}
                      setCitrullineFilterLink={setCitrullineFilterLink}
                      setBetaFilter={setBetaFilter}
                      setBetaFilterLink={setBetaFilterLink}
                      setMassFilter={setMassFilter}
                      setMassFilterLink={setMassFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/compare"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <Compare
                      apiStatus={apiStatus}
                      isLoggedIn={isLoggedIn}
                      isVerified={isVerified}
                      userCountry={userCountry}
                    />
                  )
                }
              />
              <Route
                path="/betaalanine"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <BetaFetchFilter
                      userCountry={userCountry}
                      setBetaFilter={setBetaFilter}
                      setBetaFilterLink={setBetaFilterLink}
                    /> // added productGroup
                  )
                }
              />
              <Route
                path="/betaalanine/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <BetaExample
                      userCountry={userCountry}
                      setBetaFilter={setBetaFilter}
                      setBetaFilterLink={setBetaFilterLink}
                    /> // added productGroup
                  )
                }
              />
              <Route
                path="/betaalanine/:brand/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <BetaProduct
                      betaFilter={betaFilter}
                      betaFilterLink={betaFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/citrulline"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CitrullineFetchFilter
                      userCountry={userCountry}
                      setCitrullineFilter={setCitrullineFilter}
                      setCitrullineFilterLink={setCitrullineFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/citrulline/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CitrullineExample
                      userCountry={userCountry}
                      setCitrullineFilter={setCitrullineFilter}
                      setCitrullineFilterLink={setCitrullineFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/citrulline/:brand/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CitrullineProduct
                      citrullineFilter={citrullineFilter}
                      citrullineFilterLink={citrullineFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/creatine"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CreatineFetchFilter
                      userCountry={userCountry}
                      setCreatineFilter={setCreatineFilter}
                      setCreatineFilterLink={setCreatineFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/creatine/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CreatineExample
                      userCountry={userCountry}
                      setCreatineFilter={setCreatineFilter}
                      setCreatineFilterLink={setCreatineFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/creatine/:brand/:type/:flavor/:servings/:region/:patent"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <CreatineProduct
                      creatineFilter={creatineFilter}
                      creatineFilterLink={creatineFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/electrolyte"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ElectrolyteFilter
                      userCountry={userCountry}
                      setElectrolyteFilter={setElectrolyteFilter}
                      setElectrolyteFilterLink={setElectrolyteFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/electrolyte/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ElectrolyteExample
                      userCountry={userCountry}
                      setElectrolyteFilter={setElectrolyteFilter}
                      setElectrolyteFilterLink={setElectrolyteFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/electrolyte/:brand/:type/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ElectrolyteProduct
                      electrolyteFilter={electrolyteFilter}
                      electrolyteFilterLink={electrolyteFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/massgainer"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MassFilter
                      userCountry={userCountry}
                      setMassFilter={setMassFilter}
                      setMassFilterLink={setMassFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/massgainer/:brand/:subtitle/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <MassProduct
                      massFilter={massFilter}
                      massFilterLink={massFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/proteinpowder"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinFetchFilter
                      userCountry={userCountry}
                      setProteinFilter={setProteinFilter}
                      setProteinFilterLink={setProteinFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/proteinpowder/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinExample
                      userCountry={userCountry}
                      setProteinFilter={setProteinFilter}
                      setProteinFilterLink={setProteinFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/proteinpowder/:brand/:type/:flavor/:size/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <ProteinPowderProduct
                      proteinFilter={proteinFilter}
                      proteinFilterLink={proteinFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/preworkout"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <PreFetchFilter
                      userCountry={userCountry}
                      setPreFilter={setPreFilter}
                      setPreFilterLink={setPreFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/preworkout/examples"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <PreExample
                      userCountry={userCountry}
                      setPreFilter={setPreFilter}
                      setPreFilterLink={setPreFilterLink}
                    />
                  )
                }
              />
              <Route
                path="/preworkout/:brand/:subtitle/:flavor/:servings/:region"
                element={
                  apiStatus !== 200 ? (
                    <Loading apiStatus={apiStatus} />
                  ) : (
                    <PreworkoutProduct
                      preFilter={preFilter}
                      preFilterLink={preFilterLink}
                    />
                  )
                }
              />
              <Route path="*" element={<Error />} />
              {/* <Route path="/loading" element={<Loading />} /> */}
            </Routes>
          </ContentWrap>
          <Footer />
        </PageContainer>
      </HelmetProvider>
    </>
  );
};

export default App;
